import React, { useState } from 'react';
import { Card, CardBody, Tabs, TabsHeader, Tab, Typography } from '@material-tailwind/react';
import MainLayout from "../components/MainLayout";

const tabs = [
    { label: 'Staffs', value: 'staffs' },
    { label: 'Templates', value: 'templates' },
    { label: 'Vacancy', value: 'vacancy' },
    { label: 'Expression of EOI', value: 'expressionOfEOI' },
    { label: 'Applicant', value: 'applicant' },
];

const Content = () => {
    const [activeTab, setActiveTab] = useState('staffs');

    const renderContent = () => {
        switch (activeTab) {
            case 'staffs':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Total Staffs
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    200
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Active Staffs
                                </Typography>
                                <Typography variant="h3" color="green">
                                    150
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Success Staffs
                                </Typography>
                                <Typography variant="h3" color="teal">
                                    100
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Failed Staffs
                                </Typography>
                                <Typography variant="h3" color="red">
                                    50
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Staffs on Stipend
                                </Typography>
                                <Typography variant="h3" color="purple">
                                    80
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                );
            case 'templates':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Total Templates
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    35
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Active Templates
                                </Typography>
                                <Typography variant="h3" color="green">
                                    150
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Success Templates
                                </Typography>
                                <Typography variant="h3" color="teal">
                                    100
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Failed Templates
                                </Typography>
                                <Typography variant="h3" color="red">
                                    50
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Templates on Stipend
                                </Typography>
                                <Typography variant="h3" color="purple">
                                    80
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                );
            case 'vacancy':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <Card className="!h-[160px]">
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Total Vacancies
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    10
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Active Vacancies
                                </Typography>
                                <Typography variant="h3" color="green">
                                    150
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Success Vacancies
                                </Typography>
                                <Typography variant="h3" color="teal">
                                    100
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card className="!h-[160px]">
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Failed Vacancies
                                </Typography>
                                <Typography variant="h3" color="red">
                                    50
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                );
            case 'expressionOfEOI':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <Card className="!h-[160px]">
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Expressions of EOI
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    25
                                </Typography>
                            </CardBody>
                        </Card>
                         <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Active Expressions of EOI
                                </Typography>
                                <Typography variant="h3" color="green">
                                    150
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Success Expressions of EOI
                                </Typography>
                                <Typography variant="h3" color="teal">
                                    100
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card className="!h-[160px]">
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Failed Expressions of EOI
                                </Typography>
                                <Typography variant="h3" color="red">
                                    50
                                </Typography>
                            </CardBody>
                        </Card>

                    </div>
                );
            case 'applicant':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <Card className="!h-[160px]">
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Active Applicants
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    100
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Successful Applicants
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    50
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Failed Applicants
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    20
                                </Typography>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Typography variant="h6" className="mb-2">
                                    Total Applicants
                                </Typography>
                                <Typography variant="h3" color="blue">
                                    150
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                );
            default:
                return null;
        }
    };

    const handleClick = (value) => {
        setActiveTab(value);
    }

    return (
        <>
                    <div className="container mx-auto p-4">
                        {/*<Typography variant="h4" className="mb-4">*/}
                        {/*    Dashboard*/}
                        {/*</Typography>*/}
                        <Tabs value={activeTab} >
                            <TabsHeader>
                                {tabs.map((tab) => (
                                    <Tab key={tab.value} value={tab.value} onClick={() => handleClick(tab.value)}>
                                        {tab.label}
                                    </Tab>
                                ))}
                            </TabsHeader>
                        </Tabs>
                        <div className="mt-10">
                            {renderContent()}
                        </div>
                    </div>
        </>
    );
};


export default function DashboardPage() {
    return (
        <>
        <MainLayout title={"Dashboard"} childTwo={<Content />} />
        </>
    )
}
