import React from "react";
import {ChevronDown} from "lucide-react";

export default function MainLayout({title, childOne, childTwo}) {
    return (
        <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full min-w-full border-none pt-6">
            <div className="flex gap-5 justify-between items-start  w-full max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-4 px-5 text-2xl font-medium leading-8 text-slate-600 items-center pb-4">
                    <div className="bg-blue-400  rounded-full flex items-center justify-center p-0.5">
                        <ChevronDown className="max-w-[15px] max-h-[15px] text-white"/>
                    </div>
                    <span className="flex-auto font-bold">{title}</span>
                </div>

                {childOne}

            </div>
            <div
                className="flex flex-col rounded-none bg-gray-50 dark:bg-gray-900 border rounded-tl-xl border-solid shadow-sm max-md:px-5 max-md:max-w-full h-[calc(100vh-145px)]">
                {childTwo}
            </div>
        </div>

// <div className="grid max-md:max-w-full min-w-full border-none pt-6 bg-red-200 min-h-full">
    )
}

//     <div
//         className="flex justify-between items-start self-center w-full max-md:flex-wrap max-md:max-w-full">
//         <div className="flex gap-4 px-5 text-2xl font-medium leading-8 text-slate-600 items-center pb-4">
//             <div>
//                 <img loading="lazy"
//                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc6f7d638546e0b398c4bbab81c51f3fa3b90984214c91e70f04f324542dd0b5?apiKey=ae76636b06d44b88a66a58519c620a05&"
//                      alt="" className="shrink-0 self-start aspect-square w-[18px]"/>
//             </div>
//             <span className="flex-auto font-bold">{title}</span>
//         </div>
//
//         {childOne}
//
//     </div>
//     <div
//         className="row-span-12 rounded-none bg-gray-50 border rounded-tl-xl border-solid shadow-sm max-md:px-5 max-md:max-w-full ">
//         {childTwo}
//     </div>
// </div>
