import './App.css';
import { ThemeProvider } from "@/components/theme-provider"
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import VacanciesPage from "./pages/vacancy/VacanciesPage";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/error-page";
import StaffsPage from "./pages/staff/StaffsPage";
import Logout from "./components/router/Logout";
import Root from "./components/router/Root";
import VacancyDetails from "./pages/vacancy/tabs/VacancyDetails";
import { Toaster } from "@/components/ui/sonner"
import EOIPage from "./pages/eoi/EOIPage";
import VacancyApplicantList from "./pages/vacancy/tabs/VacancyAppllicantList";
import OtpPage from "./pages/OtpPage";
import VacancyTemplatePage from "./pages/vacancy/VacancyTemplatePage";
import NewVacancy from "./pages/vacancy/edit/NewVacancy";
import ChatSpacePage from "./pages/ChatSpacePage";
import DashboardPage from "./pages/DashboardPage";
import VacancyShortList from "./pages/vacancy/tabs/VacancyShortList";
import ReplacementVacancyForm from "./pages/vacancy/edit/ReplacementVacancy";
import EoiDetails from './pages/eoi/EoiDetails';
import EoiChart from './pages/eoi/EoiChart';
import VacancyChart from './pages/vacancy/VacancyChart';
import SettingsPage from "./pages/SettingsPage";
import MyProfilePage from "./pages/MyProfilePage";
import ApplicantPage from "./pages/applicant/ApplicantPage";
import NewApplicant from "./pages/applicant/edit/NewApplicant";

const router = createBrowserRouter([
    { path: "", element: <Navigate to="/vacancy" /> },
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            { path: "", element: <Navigate to="/vacancy" replace /> },
            {
                path: "staff", children: [
                    { path: "", element: <StaffsPage /> },
                    { path: ":staffId", element: <StaffsPage /> },
                ]
            },
            {
                path: "vacancy", element: <VacanciesPage />, children: [
                    { path: "", element: <VacancyChart /> },
                    { path: ":ID", element: <VacancyDetails /> },
                    { path: ":ID/applicants", element: <VacancyApplicantList /> },
                    { path: ":ID/shortlisted", element: <VacancyShortList /> },
                    { path: "new", element: <NewVacancy /> },
                    { path: ":ID/edit", element: <NewVacancy /> },
                    { path: "new/:ID", element: <NewVacancy /> }, // :ID = templateID
                    { path: "replacement", element: <ReplacementVacancyForm /> },
                    { path: "replacement/:ID/edit" , element: <ReplacementVacancyForm /> }
                ]
            },
            { path: "settings", element: <SettingsPage /> },
            { path: "vacancy/templates", element: <VacancyTemplatePage /> },

            { path: "applicant", element: <ApplicantPage /> , children: [
                    { path: "", element: <NewApplicant /> },
                 ]
            },
            {
                path: "eoi", element: <EOIPage />, children: [
                    { path: "", element: <EoiChart /> },
                    { path: ":ID", element: <EoiDetails /> },
                    { path: "new", element: <EoiDetails /> },
                ]
            },
            { path: "dashboard", element: <DashboardPage />, },
            { path: "chat", element: <ChatSpacePage />, },
            { path: "/profile", element: <MyProfilePage /> },
        ],
    },
    { path: "/logout", element: <Logout /> },
    { path: "/login", element: <LoginPage />, },
    { path: "/otp", element: <OtpPage />, },
    // { path: "*" , element: <Navigate to='/' />}
]);


function App() {

    return (
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <RouterProvider router={router} />
            <Toaster
                richColors
                position='top-right'
                visibleToasts={"1"}
                toastOptions={{}}
            />
        </ThemeProvider>

        // <BrowserRouter>
        //     <Routes>
        //         <Route path="/"  element={<Home/>}/>
        //         <Route path="/login" element={<Login/>}/>
        //         <Route path="/about" element={<About/>}/>
        //         <Route path="*" element={<h1>Not Found</h1>}/>
        //     </Routes>
        // </BrowserRouter>
    );
}

export default App;
