import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select";
import {Button} from "../../../components/ui/button";
import {Input} from "@/components/ui/input";
import {
    Camera,
    Download,
    FileBadge2,
    FileCheck2,
    FileHeart,
    FileText,
    FileVolume2,
    ImageUp,
    MapPinned,
    Trash2
} from "lucide-react";
import {useState} from "react";
import {httpApi} from "../../../api/http.api";
import HistoryLog from "../../../components/historyLog";

const FileTypeIcon = (type) => {
    switch (type) {
        case "Photograph":
            return <Camera className="text-gray-500"/>;
        case "Passport":
            return <FileVolume2 className="text-gray-500"/>;
        case "Resume":
            return <FileHeart className="text-gray-500"/>;
        case "D.B.S":
            return <FileBadge2 className="text-gray-500"/>;
        case "National Insurance":
            return <FileCheck2 className="text-gray-500"/>;
        case "Address Proof":
            return <MapPinned className="text-gray-500"/>;
        case "Filled Application Form":
            return <FileText className="text-gray-500"/>;
        default:
            return <FileText className="text-gray-500"/>;
    }
};

export default function DocumentsTab({eoi, isEdit}) {
    const [documents, setDocuments] = useState([]);
    const [docType, setDocType] = useState(null);
    const [fileType, setFileType] = useState(null);

    const document_types = [
        {id: "1", value: "Photograph"},
        {id: "2", value: "Passport"},
        {id: "3", value: "Resume"},
        {id: "4", value: "D.B.S"},
        {id: "5", value: "National Insurance"},
        {id: "6", value: "Address Proof"},
        {id: "7", value: "Filled Application Form"},
    ];

    const handleSelect = (value) => {
        setDocType(value);
        // Update the accepted file type based on the selection
        if (value === "Photograph") {
            setFileType("img");
        } else {
            setFileType("pdf");
        }
    };

    const handleSelectDoc = (value) => {
        document.getElementById("Docfile").click();
    }

    const handleFileChange = (e) => {
        if (docType === null) {
            alert("Please select a document type");
            return;
        }
        const newDocument = {
            name: e.target.files[0].name,
            file: e.target.files[0],
            type: docType,
        };
        setDocuments([...documents, newDocument]);
    }

    const handleRemove = (index) => () => {
        const newDocuments = documents.filter((_, i) => i !== index);
        setDocuments(newDocuments);
    };

    const handleUpload = () => {
        console.log("uploading..")
        const formData = new FormData();
        documents.forEach((document) => {
            formData.append("file", document.file);
        });
        httpApi.post(`/eoi/${eoi.id}/documents`, formData)
            .then((res) => {
                console.log(res.data);
                alert("File uploaded successfully");
            })
            .catch((err) => {
                console.log(err);
                alert("Failed to upload file");
            });
    };

    return (
        <div className="px-3 py-6">
            {/* Documents */}
            <section className="w-full mt-6">
                <div className="grid grid-cols-2 mt-6 ">
                    <div>
                        {documents.map((document, index) => (
                            <div key={index} className="flex items-center gap-4 justify-between p-3 ">
                                <div className="flex-1 flex gap-3 items-center">
                                    {FileTypeIcon(document.type)}
                                    <span className="text-lg font-bold text-gray-500">
                    {document?.type}
                  </span>
                                </div>
                                <div className="flex items-center justify-center gap-3">
                                    <Download className="cursor-pointer text-gray-600"/>
                                    <Trash2
                                        onClick={handleRemove(index)}
                                        className="cursor-pointer text-gray-600"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    {isEdit && <div className="space-y-6 w-full">
                        <div onClick={handleSelectDoc}
                             className="border rounded-[20px] bg-gray-100 flex items-center justify-center flex-col cursor-pointer">
                            <ImageUp className="h-28 w-28 m-4 text-gray-500"/>
                            <p className="text-gray-700 my-3">
                                click to upload
                            </p>
                            <Input
                                type="file"
                                name="Docfile"
                                id="Docfile"
                                className="hidden"
                                onChange={handleFileChange}
                                accept={fileType === "img" ? "image/*" : "application/pdf"}
                            />
                        </div>
                        <Select
                            onValueChange={(value) => {
                                handleSelect(value)
                            }}>
                            <SelectTrigger className="w-full" custom={true}>
                                <SelectValue placeholder="Document type"/>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {document_types.map((option) => (
                                        <SelectItem
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.value}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>

                        <div className="flex items-center justify-end gap-6">
                            <Button className="rounded-full bg-gray-400 text-white font-bold px-8"
                                    onClick={handleUpload}>
                                Upload
                            </Button>
                            {/*<Button className="rounded-full bg-blue-400 text-white font-bold px-8">*/}
                            {/*  Save*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                    }

                </div>

                {eoi.id && <HistoryLog modelName={"eoi"} modelID={eoi.id}/>}

            </section>
        </div>
    );
}
