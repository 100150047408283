import RequireAuth from "@/components/router/RequireAuth";
import NavBar from "./NavBar";

export default function Root() {
    return (
        <RequireAuth>
            <NavBar/>
        </RequireAuth>
    )
}
