import React, {useEffect, useState} from "react";
import {Circle, Eye, ImageUp, Trash2} from "lucide-react";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger,} from "@/components/ui/select";
import {zodResolver} from "@hookform/resolvers/zod"
import {useForm} from "react-hook-form"
import {z} from "zod"
import {Button} from "@/components/ui/button"
import {Form, FormControl, FormField, FormItem, FormMessage,} from "@/components/ui/form"
import {Input} from "@/components/ui/input"
import HistoryLog from "@/components/historyLog";
import {httpApi} from "../../../api/http.api";
import {notificationController} from "../../../controllers/notificationController";
import {Input as MInput} from "@material-tailwind/react";

const Icon = ({icon}) => {
    return <div className=" bg-slate-100 rounded-[40%] p-2 border">{icon}</div>;
};

const formSchema = z.object({
    first_name: z.string().min(2, {message: 'First name at least 2 letter'}).max(50).nonempty({message: 'First name is required'}),
    middle_name: z.string().min(2).max(50),
    last_name: z.string().min(2).max(50),
    mobile: z.string().min(2).max(50),
    city: z.string().min(2).max(50),
    address: z.string().min(2).max(50),
    country: z.string().min(2).max(50),
    zip_code: z.string().min(4).max(10),
})

export default function PersonalTab({eoi, isEdit}) {

    const [selectedFile, setSelectedFile] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [priority, setPriority] = useState("");
    const [open, setOpen] = useState(false)
    // const [isEdit, setIsEdit] = useState(false)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile: "",
            city: "",
            address: "",
            country: "",
            zip_code: "",
        },
    })

    function onSubmit(values) {
        // console.log(values)
        // const json = JSON.stringify(values, null, 2)
        if (eoi?.id) {
            httpApi.patch(`/eoi/${eoi.id}`, values).then(response => {
                notificationController.success({message: 'EOI Updated'});
                // window.location.href = `/eoi/${response.data.id}`
                window.location.href = `/eoi`
            }, error => {
                notificationController.error(error)
            })
        } else {
            httpApi.post('/eoi', values).then(response => {
                notificationController.success({message: 'EOI Created'});
                window.location.href = `/eoi`
            }, error => {
                notificationController.error(error)
            })
        }
    }

    const handleSelectImage = (e) => {
        document.getElementById("file").click();
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImgUrl(URL.createObjectURL(file));
        form.setValue("image", file);
    }


    const handleImageRemove = () => {
        setImgUrl(null);
        form.setValue("image", null);
    }

    function getPriorityClass() {
        switch (priority) {
            case "high":
                return "text-red-600";
            case "medium":
                return "text-yellow-600";
            case "low":
                return "text-green-600";
            default:
                return "text-gray-200";
        }
    }

    const handleopenSelect = (open) => {
        setOpen(open);
    }

    useEffect(() => {
        if (eoi) {
            form.setValue('first_name', eoi?.first_name)
            form.setValue('middle_name', eoi?.middle_name)
            form.setValue('last_name', eoi?.last_name)
            form.setValue('mobile', eoi?.mobile)
            form.setValue('city', eoi?.city)
            form.setValue('address', eoi?.address)
            form.setValue('country', eoi?.country)
            form.setValue('zip_code', eoi?.zip_code)
            setPriority(eoi?.priority)
            console.log(form.getValues())
        }
    }, [eoi])

    return (
        <>
            <div className=" pt-2 ">
                <div className="absolute -top-8 right-0 cursor-pointer text-blue-500 ">
                    {/*{isEdit ? (*/}
                    {/*    <Dna onClick={() => setIsEdit(false)} />) : (*/}
                    {/*    <Pencil onClick={() => setIsEdit(true)} />*/}
                    {/*)}*/}
                </div>

                {/* form */}
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                        {/* personal */}
                        <section className="space-y-6">
                            <div className={"flex justify-between items-center "}>
                                <div className="flex justify-center items-center gap-2">
                                    {/*<span>*/}
                                    {/*    <User className="text-gray-500 w-5 h-5"/>*/}
                                    {/*</span>*/}
                                    {/*<h1 className="font-semibold">Personal</h1>*/}
                                </div>

                                <div
                                    className={""}>
                                    <Select onValueChange={(value) => setPriority(value)} open={open}
                                            onOpenChange={handleopenSelect}>
                                        <SelectTrigger
                                            disabled={!isEdit}
                                            className="w-fit font-bold rounded-full !h-8 bg-gray-100 text-sm  border border-gray-500 !outline-none !ring-0 !select-none">
                                            <div onClick={handleopenSelect}
                                                 className="flex items-center justify-between flex-nowrap px-2 gap-4">
                                                <span className="text-gray-600">Priority level</span><Circle
                                                className={`h-3 w-3 ${getPriorityClass()}`}/>
                                            </div>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem value="high">
                                                    <div className={"flex gap-4 px-2 items-center"}>
                                                        <span>High</span>
                                                        <Circle className="h-3 w-3 text-red-400"/>
                                                    </div>
                                                </SelectItem>
                                                <SelectItem value="medium">
                                                    <div className={"flex gap-4 px-2 items-center"}>
                                                        <span>Medium</span>
                                                        <Circle className="h-3 w-3 text-yellow-400"/>
                                                    </div>
                                                </SelectItem>
                                                <SelectItem value="low">
                                                    <div className={"flex gap-4 px-2 items-center"}>
                                                        <span>Low</span>
                                                        <Circle className="h-3 w-3 text-green-400"/>
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>


                            </div>
                            <div className="cursor-pointer !-mt-4">
                                {imgUrl !== null ? (
                                    <div className="flex flex-col w-fit">
                                        <div className="w-28 h-28 rounded-full border overflow-hidden"
                                             onClick={handleSelectImage}>
                                            <img src={imgUrl} alt="profile"
                                                 className="w-full h-full object-cover "/>
                                        </div>
                                        <div className="flex items-center justify-center py-2 gap-3">
                                            <Eye className="text-blue-500"/>
                                            <Trash2 className="text-red-400" onClick={handleImageRemove}/>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="flex items-center justify-center  bg-gray-100 rounded-full  w-28 h-28"
                                        onClick={handleSelectImage}>
                                        <ImageUp className="h-10 w-10 text-gray-500"/>
                                    </div>
                                )}
                                <Input
                                    type="file"
                                    name="file"
                                    className="hidden"
                                    id="file"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                            </div>

                            <div className="grid grid-cols-2  gap-4">
                                <FormField
                                    control={form.control}
                                    name="first_name"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="First name"
                                                    placeholder="First name"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="middle_name"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="Middle name"
                                                    placeholder="Middle name"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="last_name"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    variant="standard"
                                                    disabled={!isEdit}
                                                    label="Last name"
                                                    placeholder="Last name"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 justify-between gap-4">
                                <FormField
                                    className=""
                                    control={form.control}
                                    name="mobile"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="Mobile"
                                                    placeholder="Mobile"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="city"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="City"
                                                    placeholder="City"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 justify-between gap-4">
                                <FormField
                                    control={form.control}
                                    name="address"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="Address"
                                                    placeholder="Address"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="country"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="Country"
                                                    placeholder="Country"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 justify-between gap-4">
                                <div className="flex items-end justify-center gap-2"></div>
                                <FormField
                                    control={form.control}
                                    name="zip_code"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormControl>
                                                <MInput
                                                    disabled={!isEdit}
                                                    variant="standard"
                                                    label="Zip code"
                                                    placeholder="Zip code"
                                                    className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] '}`}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            {isEdit && (
                                <div className="flex items-center justify-end">
                                    <Button type="submit"
                                            className="rounded-full bg-blue-400 text-white font-bold px-8">
                                        Save
                                    </Button>
                                </div>
                            )}
                        </section>


                    </form>
                </Form>

                {eoi.id && <HistoryLog modelName={"eoi"} modelID={eoi.id}/>}

            </div>
        </>
    );
}
