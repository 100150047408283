import React, { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import PersonalTab from "./tabs/PersonalTab";
import TraningTab from "./tabs/TraningTab";
import InterviewTab from "./tabs/InterviewTab";
import RTWTab from "./tabs/RTWTab"




export default function NewApplicant() {
  const { ID } = useParams();
  const { pathname } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState("personal");
  const data = [
    {
      label: "Personal",
      value: "personal",
      Component: PersonalTab,
    },
    {
      label: "RTW",
      value: "rtw",
      Component: RTWTab,
    },
    {
      label: "Training",
      value: "training",
      Component: TraningTab,
    },
    {
      label: "Interview",
      value: "interview",
      Component: InterviewTab,
    },

  ];

  return (
    <>
      <div className="border rounded-xl bg-white dark:bg-slate-800 p-4 ">
        <Tabs value={activeTab}>
          <TabsHeader
              className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
              indicatorProps={{
                className:
                    "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
              }}
          >
            {data.map(({ label, value }) => (
                <Tab
                    key={value}
                    value={value}
                    onClick={() => setActiveTab(value)}
                    className={activeTab === value ? "text-gray-900" : ""}
                >
                  {label}
                </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, Component }) => (
                <TabPanel key={value} value={value}>
                  <Component  />
                </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </>
  );
}
