import React from "react";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot} from "../components/ui/input-otp";


const Divider = ({className}) => (
    <div className={`shrink-0 w-px border border-solid ${className}`}/>
);

const Icon = ({src, alt, className}) => (
    <img loading="lazy" src={src} alt={alt} className={className}/>
);
export default function OtpPage() {
    const [value, setValue] = React.useState("")

    return (
        <div className="flex flex-col min-h-dvh bg-white">

            <header className="flex flex-col pt-3 w-full shadow-sm bg-neutral-100 max-md:max-w-full">
                <div
                    className="flex gap-5 justify-between items-center self-center px-5 w-full max-w-[1319px] max-md:flex-wrap max-md:max-w-full">
                    <div className="flex gap-5 self-stretch my-auto text-xs leading-5 text-slate-600">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c5a4aef61cf1b5ee5885e4c70a8baeea07f76475c566078cf5681ee3ffe1c85?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Company logo" className="shrink-0 aspect-[4] w-[73px]"/>
                        <Divider className="bg-slate-600 border-slate-600 h-[18px]"/>
                        <div className="flex-auto my-auto">Your HR Hub. Simplified!</div>
                    </div>
                    <form
                        className="flex gap-2.5 self-stretch px-3.5 py-3 text-sm font-medium leading-5 whitespace-nowrap bg-white border-0 border-solid border-slate-600 rounded-[34px] text-slate-600">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/01423d2ef7c685077346615b78c832ab6fa117eb9018ce1fce9d2b98f923da46?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Search icon" className="shrink-0 w-3.5 aspect-square fill-slate-600"/>
                        <label htmlFor="searchInput" className="sr-only">Search</label>
                        <input id="searchInput" type="text" placeholder="Search"
                               className="flex-auto my-auto bg-transparent border-none outline-none"/>
                    </form>
                    <nav className="flex gap-3 self-stretch my-auto">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ceb5efce947d11ed31894b717e28b53cea48cb995073fae0bb38f0ecbe92311a?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Notification icon" className="shrink-0 aspect-square w-[18px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3cbdeb3d90ac01717e378a285cac0dc55f5654a68204382aed5cd916b007d1cf?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Messages icon" className="shrink-0 aspect-[0.83] fill-slate-600 w-[15px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce0b0d783a7f5435d21cf8b00a9b3a59d7263e92117f876e6f29596ebf74214?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="User profile icon" className="shrink-0 self-start aspect-[1.06] w-[18px]"/>
                    </nav>
                </div>
                <div className="mt-2.5 w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full"/>
            </header>

            <main className="flex-1 flex items-center justify-center">
                <div
                    className="flex flex-col items-center px-11 py-16 rounded-lg shadow-sm bg-neutral-100 max-w-[453px]">
                    <div className="text-2xl font-medium leading-9 text-center text-slate-600">
                        OTP Verification{" "}
                    </div>
                    <div className="mt-11 w-full text-sm leading-5 text-center text-slate-600">
                        Enter the code from the SMS we sent <br/>
                        to +44xxxxx77568
                    </div>
                    <div className="mt-8 text-sm font-bold leading-5 text-center text-gray-400">
                        02:32
                    </div>
                    <div className="flex gap-5 self-stretch px-px mt-7">
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border-solid border-[3px] border-slate-600 h-[45px] w-[45px] text-center"/>*/}
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border border-gray-400 border-solid h-[45px] w-[45px] text-center"/>*/}
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border border-gray-400 border-solid h-[45px] w-[45px] text-center"/>*/}
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border border-gray-400 border-solid h-[45px] w-[45px] text-center"/>*/}
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border border-gray-400 border-solid h-[45px] w-[45px] text-center"/>*/}
                    {/*    <input type="text" maxLength="1" className="shrink-0 bg-white rounded-md border border-gray-400 border-solid h-[45px] w-[45px] text-center"/>*/}
                    <InputOTP maxLength={6}
                              value={value}
                              onChange={(value) => setValue(value)}
                    >
                        <InputOTPGroup className={"bg-white"}>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                        {/*</InputOTPGroup>*/}
                        {/*<InputOTPSeparator />*/}
                        {/*<InputOTPGroup className={"bg-white"}>*/}
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                        </InputOTPGroup>
                    </InputOTP>
                    </div>

                    <div className="mt-6 text-sm font-bold leading-5 text-center text-slate-600">
                        <span className="">Don’t receive th OTP?</span>{" "}
                        <span className="font-medium text-slate-600">Resend</span>
                    </div>
                    <button
                        className="justify-center items-center self-stretch px-16 py-4 mt-8 text-xl font-medium leading-8 text-center text-white whitespace-nowrap rounded-lg bg-slate-600">
                        Submit
                    </button>
                </div>
            </main>

        </div>
)

}
