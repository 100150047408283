// import { notification } from 'antd';
// import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled, StopFilled } from '@ant-design/icons';
// import { ArgsProps } from 'antd/lib/notification';
import {toast} from "sonner"
//
// interface IconWrapperProps {
//   $isOnlyTitle: boolean;
// }
//
// const IconWrapper = styled.div<IconWrapperProps>`
//   font-size: ${(props) => (props.$isOnlyTitle ? '2rem' : '3rem')};
//   line-height: 2rem;
// `;
//
// const EmptyDescription = styled.div`
//   margin-top: -0.75rem;
// `;

// type NotificationProps = ArgsProps;

interface NotificationProps {
    description?: string;
    message?: string;
}

const openSuccessNotification = (config: NotificationProps): void => {
    // notification.success({
    //   ...config,
    //   icon: (
    //     <IconWrapper $isOnlyTitle={!config.description}>
    //       <CheckCircleFilled className="" />
    //     </IconWrapper>
    //   ),
    //   message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    //   description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    //   className: config.description ? '' : 'notification-without-description',
    // });
    toast.success(config.message, {
        description: config.description
    })
};

const openInfoNotification = (config: NotificationProps): void => {
    // notification.info({
    //   ...config,
    //   icon: (
    //     <IconWrapper $isOnlyTitle={!config.description}>
    //       <InfoCircleFilled className="" />
    //     </IconWrapper>
    //   ),
    //   message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    //   description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    //   className: config.description ? '' : 'notification-without-description',
    // });
    toast.info(config.message, {
        description: config.description
    })
};

const openWarningNotification = (config: NotificationProps): void => {
    // notification.warning({
    //   ...config,
    //   icon: (
    //     <IconWrapper $isOnlyTitle={!config.description}>
    //       <ExclamationCircleFilled className="" />
    //     </IconWrapper>
    //   ),
    //   message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    //   description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    //   className: config.description ? '' : 'notification-without-description',
    // });
    toast.warning(config.message, {
        description: config.description
    })
};

const openErrorNotification = (config: NotificationProps): void => {
    // notification.error({
    //   ...config,
    //   icon: (
    //     <IconWrapper $isOnlyTitle={!config.description}>
    //       {/*<StopFilled className="" />*/}
    //       <CircleAlertIcon/>
    //     </IconWrapper>
    //   ),
    //   message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    //   description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    //   className: config.description ? '' : 'notification-without-description',
    // });

    toast.error(config.message, {
        description: config.description
    })

};

export const notificationController = {
    success: openSuccessNotification,
    info: openInfoNotification,
    warning: openWarningNotification,
    error: openErrorNotification,
};
