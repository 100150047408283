import PropTypes from 'prop-types';

const ReactTablePagination = ({
                                  dataLength,
                                  page,
                                  gotoPage,
                                  canPreviousPage,
                                  pageOptions,
                                  pageSize,
                                  pageIndex,
                                  previousPage,
                                  nextPage,
                                  canNextPage,
                                  setPageSize,
                                  manualPageSize,
                              }) => {
    // console.log(pageOptions)
    const arrayPageIndex = (pageIndex - 2) < 0
        ? pageOptions.slice(0, pageIndex + 3)
        : pageOptions.slice((pageIndex - 2), (pageIndex + 3));

    return (
        // <Pagination className="pagination" dir="ltr">
        //     <div className="pagination">
        //         <PaginationLink
        //             className="pagination__link pagination__link--arrow"
        //             type="button"
        //             onClick={() => gotoPage(0)}
        //             disabled={!canPreviousPage}
        //         >
        //             {/*<ChevronDoubleLeftIcon className="pagination__link-icon" />*/}
        //         </PaginationLink>
        //         <PaginationLink
        //             className="pagination__link pagination__link--arrow"
        //             type="button"
        //             onClick={previousPage}
        //             disabled={!canPreviousPage}
        //         >
        //             {/*<ChevronLeftIcon className="pagination__link-icon" />*/}
        //         </PaginationLink>
        //         {arrayPageIndex.map(i => (
        //             <PaginationItem
        //                 className="pagination__item"
        //                 active={pageIndex === i}
        //                 key={i}
        //             >
        //                 <PaginationLink
        //                     key={i}
        //                     className="pagination__link"
        //                     type="button"
        //                     onClick={() => gotoPage(i)}
        //                 >
        //                     {i + 1}
        //                 </PaginationLink>
        //             </PaginationItem>
        //         ))}
        //         <PaginationItem className="pagination__item">
        //             <PaginationLink
        //                 className="pagination__link pagination__link--arrow"
        //                 type="button"
        //                 onClick={nextPage}
        //                 disabled={!canNextPage}
        //             >
        //                 {/*<ChevronRightIcon className="pagination__link-icon" />*/}
        //             </PaginationLink>
        //         </PaginationItem>
        //         <PaginationItem className="pagination__item">
        //             <PaginationLink
        //                 className="pagination__link pagination__link--arrow"
        //                 type="button"
        //                 onClick={() => gotoPage(pageOptions.length - 1)}
        //                 disabled={!canNextPage}
        //             >
        //                 {/*<ChevronDoubleRightIcon className="pagination__link-icon" />*/}
        //             </PaginationLink>
        //         </PaginationItem>
        //         <PaginationItem className="pagination__item pagination-info">
        //             Showing {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length} of {dataLength}
        //         </PaginationItem>
        //         {manualPageSize.length > 1 && (
        //             <PaginationItem className="pagination__item">
        //                 <Form className="pagination__select-form ">
        //                     <Input
        //                         className="pagination__item pagination-info"
        //                         type="select"
        //                         name="select"
        //                         id="exampleSelect"
        //                         value={pageSize}
        //                         onChange={(event) => {
        //                             setPageSize(Number(event.target.value));
        //                         }}
        //                     >
        //                         {manualPageSize.map(item => (
        //                             <option className="pagination__item pagination__item-option" key={item} value={item}>
        //                                 Show {item}
        //                             </option>
        //                         ))}
        //                     </Input>
        //                 </Form>
        //             </PaginationItem>
        //         )}
        //     </div>
        // </Pagination>

        // <Pagination>
        //     <PaginationContent>
        //         <PaginationItem>
        //             <PaginationPrevious
        //                 disabled={!canPreviousPage}
        //                 onClick={previousPage}
        //             />
        //         </PaginationItem>
        //         <PaginationItem>
        //             <PaginationLink onClick={() => gotoPage(0)}>
        //                 1</PaginationLink>
        //         </PaginationItem>
        //         <PaginationItem>
        //             <PaginationLink href="#" isActive>
        //                 2
        //             </PaginationLink>
        //         </PaginationItem>
        //         <PaginationItem>
        //             <PaginationLink href="#">3</PaginationLink>
        //         </PaginationItem>
        //         <PaginationItem>
        //             <PaginationEllipsis />
        //         </PaginationItem>
        //         <PaginationItem>
        //             <PaginationNext
        //                 disabled={!canNextPage}
        //                 onClick={nextPage}
        //             />
        //         </PaginationItem>
        //     </PaginationContent>
        // </Pagination>

        <div className="flex justify-between items-center">
            <span
                className="text-gray-700">Showing {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length} of {dataLength}</span>
            <div className="flex items-center space-x-2">
                <ChevronLeftIcon className="h-5 w-5 text-gray-400 cursor-pointer" disabled={!canPreviousPage}
                                 onClick={previousPage}/>
                {arrayPageIndex.map(i => (
                    <span
                        className={pageIndex === i ? "font-bold cursor-pointer text-purple-600" : "cursor-pointer text-gray-500"}
                        onClick={() => gotoPage(i)} key={i}>
                        {i + 1}
                    </span>
                ))}
                {/*<CircleEllipsisIcon className="h-5 w-5 text-gray-400"/>*/}
                {/*<span className="text-gray-500">40</span>*/}
                <ChevronRightIcon className="h-5 w-5 text-gray-400 cursor-pointer" disabled={!canNextPage}
                                  onClick={nextPage}/>
            </div>
        </div>
    );
};

ReactTablePagination.propTypes = {
    dataLength: PropTypes.number.isRequired,
    page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    gotoPage: PropTypes.func.isRequired,
    canNextPage: PropTypes.bool.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

ReactTablePagination.defaultProps = {
    manualPageSize: [10, 20, 30, 40],
};

export default ReactTablePagination;

function ChevronLeftIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m15 18-6-6 6-6"/>
        </svg>
    )
}


function ChevronRightIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m9 18 6-6-6-6"/>
        </svg>
    )
}


function CircleEllipsisIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10"/>
            <path d="M17 12h.01"/>
            <path d="M12 12h.01"/>
            <path d="M7 12h.01"/>
        </svg>
    )
}

