import * as React from "react"

import { cn } from "@/lib/utils"

const Input = React.forwardRef(({ className, type, custom, ...props }, ref) => {
  return (
    (<input
      type={type}
      className={cn(
        "flex h-9 w-full  bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
        custom ? "!border-b-2 !border-gray-300  focus:!border-blue-500 py-2 px-3 !outline-none w-full text-base !ring-0 !shadow-none" : "border border-input rounded-md ",
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Input.displayName = "Input"

export { Input }
