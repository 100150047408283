import {usePagination, useSortBy, useTable} from "react-table"
import {Spinner} from '@/components/ui/spinner';
import {useQuery} from 'react-query'
import axios from 'axios'

import {TABLE_COLUMNS} from "./columns"
import ReactTablePagination from './ReactTablePagination'
import React, {useEffect, useMemo, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"
import {SortAscIcon, SortDescIcon} from "lucide-react";
import {Badge} from "@/components/ui/badge";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {useNavigate} from "react-router-dom";

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED';
const PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case PAGE_SORT_CHANGED:
            return {
                ...state,
                queryPageSortBy: payload,
            };
        case PAGE_FILTER_CHANGED:
            return {
                ...state,
                queryPageFilter: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

const fetchData = async (tableName, page, pageSize, pageFilter, pageSortBy) => {
    let paramStr = ''
    if (pageFilter && pageFilter.length > 0) {
        pageFilter.forEach(filter => {
            paramStr += `&${filter.key}=${filter.value}`
        });
    }
    if (pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        const sortyByDir = sortParams.desc ? 'desc' : 'asc'
        paramStr = `${paramStr}&sortby=${sortParams.id}&direction=${sortyByDir}`
    }
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/${tableName}?page=${page + 1}&limit=${pageSize}${paramStr}`
        );
        const results = response.data.data;
        // console.log(results)
        const data = {
            results: results,
            count: response.data.total
        };
        return data;
    } catch (e) {
        throw new Error(`API error:${e?.message}`);
    }
};

function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

const MyDataTable = ({tableName, onClick, filters, url = null}) => {
    const navigate = useNavigate()

    const [keyword, setKeyword] = useState([]);
    const [useFilter, setUseFilter] = useState(false);

    // useEffect(() => {
    //     if (filters) {
    //
    //     } else {
    //         filters = [
    //             {key: 'status', value: 'active'},
    //             {key: 'group', value: 'admin'}
    //         ];
    //     }
    //
    //     setUseFilter(true)
    //     setKeyword(filters)
    // }, [])


    const onClickFilterCallback = (filter) => {
        if (filter.trim() === "") {
            alert('Please enter a keyword to search!')
            return
        }
        if (filter === keyword) {
            alert('No change in search')
            return
        }
        setUseFilter(true)
        setKeyword(filter)
    }

    let columns = useMemo(() => {
        return TABLE_COLUMNS[tableName]
    }, [])

    const [{queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy}, dispatch] =
        React.useReducer(reducer, initialState);

    const {isLoading, error, data, isSuccess} = useQuery(
        [tableName, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy],
        () => fetchData(url ?? tableName, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy),
        {
            keepPreviousData: false,
            staleTime: Infinity,
        }
    );

    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: {pageIndex, pageSize, sortBy}
    } = useTable({
            columns,
            data: data?.results || [],
            initialState: {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
                sortBy: queryPageSortBy,
            },
            manualPagination: true,
            pageCount: data ? totalPageCount : null,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false
        },
        useSortBy,
        usePagination,
    );

    const statusClasses = {
        'active': 'bg-green-200 text-green-800',
        'inactive': 'bg-red-200 text-red-800',
        'deceased': 'bg-gray-200 text-gray-800',
        // Add more status types here
    };

    function RiskBadge({level}) {
        let color = "";

        switch (level) {
            case 'A':
                color = "bg-green-500";
                break;
            case 'G':
                color = "bg-yellow-500";
                break;
            case 'S':
                color = "bg-red-500";
                break;
            default:
                color = "bg-gray-500";
        }

        return (
            <span className={`inline-block rounded-full text-white text-xs font-bold ${color} px-2 py-1`}>
            {level}
        </span>
        );
    }

    useEffect(() => {
        dispatch({type: PAGE_CHANGED, payload: pageIndex});
    }, [pageIndex]);

    useEffect(() => {
        dispatch({type: PAGE_SIZE_CHANGED, payload: pageSize});
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({type: PAGE_SORT_CHANGED, payload: sortBy});
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        // if (useFilter) {
        dispatch({type: PAGE_FILTER_CHANGED, payload: filters});
        gotoPage(0);
        // }
    }, [filters, gotoPage, useFilter]);

    React.useEffect(() => {
        if (data?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data.count,
            });
        }
    }, [data?.count]);

    const handleRowClick = (rowData) => {
        // setIsSelectedRow(rowData);
        // handleTriggerContentSheet();
        // console.log(rowData)
        // redirect("/" + tableName + "/" + rowData.id)
        if (onClick) {
            onClick(rowData.id)
        }
    };

    if (error) {
        return <p className="text-center">Error</p>;
    }

    if (isLoading) {
        // return <p>Loading...</p>;
//         return <div>
// <div role="status">
//     <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
//         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
//     </svg>
//     <span class="sr-only">Loading...</span>
// </div>

//         </div>
//         ;
        return <Spinner/>;


    }
    if (isSuccess)
        return (
            <div className="">
                {/*<div className="flex items-center py-4">*/}
                {/*    <Input*/}
                {/*        placeholder="Search"*/}
                {/*        // value={(table.getColumn("email")?.getFilterValue()) ?? ""}*/}
                {/*        // onChange={(event) =>*/}
                {/*        //     table.getColumn("email")?.setFilterValue(event.target.value)*/}
                {/*        // }*/}
                {/*        className="max-w-sm"*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="">
                    <Table>
                        <TableHeader>
                            {headerGroups.map((headerGroup, index) => (
                                <TableRow key={index}>
                                    {headerGroup.headers.map((header, index) => {
                                        return (
                                            <TableHead key={index}>
                                                {header.isPlaceholder
                                                    ? null
                                                    :
                                                    header.render('Header')
                                                }
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {rows?.length ? (
                                rows.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow
                                            // row.original.id
                                            key={index}
                                            onClick={() => handleRowClick(row.original)}
                                            data-state={row.isSelected && "selected"}
                                        >
                                            {/*<Link to={"/" + tableName + "/" + row.original.id}>*/}
                                            {row.cells.map((cell, cellIndex) => (
                                                <TableCell className={"font-medium"} key={cellIndex}>
                                                    {/*{cell.column.id === 'status' && cell.value ? (*/}
                                                    {/*    <Badge*/}
                                                    {/*        className={statusClasses[cell.value.toLowerCase()] || 'bg-gray-200 text-gray-800'}*/}
                                                    {/*        variant="secondary"*/}
                                                    {/*    >*/}
                                                    {/*        /!*{capitalize(cell.value)}*!/*/}
                                                    {/*        <span className="capitalize">{cell.value}</span>*/}
                                                    {/*    </Badge>*/}
                                                    {cell.column.id === 'status' ? (
                                                        <Badge
                                                            className={cell.value ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}
                                                            variant="secondary">
                                                            <span
                                                                className="capitalize">{cell.value ? 'Active' : 'Inactive'}</span>
                                                        </Badge>
                                                    ) : cell.column.id === 'avatar' ? (
                                                        <Avatar>
                                                            <AvatarImage alt="Jane Cooper"
                                                                         src="/placeholder.svg?height=32&width=32"/>
                                                            <AvatarFallback>
                                                                {/*{user.first_name[0].toUpperCase()}{user.last_name[0].toUpperCase()}*/}
                                                                {row.original.first_name[0].toUpperCase()}{row.original.last_name[0].toUpperCase()}
                                                            </AvatarFallback>
                                                        </Avatar>
                                                    ) : cell.column.id === "risk_level" ? (
                                                        RiskBadge({level: cell.value})
                                                    ) : cell.column.id === "groups" ? (
                                                        cell.value && cell.value.map((group) => (
                                                            <span key={group.id}
                                                                  className="capitalize bg-gray-300 p-2 rounded-sm mr-1">{group.name}</span>
                                                        ))
                                                    ) : cell.column.id === "is_admin" ? (
                                                        <span
                                                            className="capitalize bg-gray-300 p-1 rounded-lg">{cell.value ? 'Admin' : 'User'}</span>
                                                    ) : (
                                                        cell.render('Cell')
                                                    )}
                                                </TableCell>
                                            ))}
                                            {/*</Link>*/}
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <div className={"m-4"}>
                    {(rows.length > 0) && (
                        <ReactTablePagination
                            page={page}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            pageCount={pageCount}
                            setPageSize={setPageSize}
                            dataLength={totalCount}
                        />
                    )}
                </div>

            </div>
        )
}

const Sorting = ({column}) => (
    <span className="react-table__column-header sortable">
      {column.isSortedDesc === undefined ? (
          <>
              <SortAscIcon/>
              <div>Sort</div>
          </>
      ) : (
          <span>
          {column.isSortedDesc
              ? <SortAscIcon/>
              : <SortDescIcon/>
          }
        </span>
      )}
    </span>
);

// const MyDataTable = ({tableName}) => {
//     return (
//         // <QueryClientProvider client={queryClient}>
//         <DataTable tableName={tableName}/>
//         // </QueryClientProvider>
//     )
// }

export default MyDataTable;
