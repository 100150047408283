import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        // <div id="error-page">
        //     <h1>Oops!</h1>
        //     <p>Sorry, an unexpected error has occurred.</p>
        //     <p>
        //         <i>{error.statusText || error.message}</i>
        //     </p>
        // </div>
    <div className="flex flex-col items-center justify-center min-h-[100dvh] bg-gray-100 dark:bg-gray-800 px-4 py-12 md:px-6 lg:px-8">
      <div className="max-w-md text-center space-y-4">
        <h1 className="text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-6xl">404</h1>
        <p className="text-lg text-gray-600 dark:text-gray-400">
          Oops, it looks like the page you were looking for doesn't exist.
        </p>
        <a
          className="inline-flex items-center justify-center rounded-md bg-gray-900 px-4 py-2 text-sm font-medium text-gray-50 shadow-sm transition-colors hover:bg-gray-900/90 focus:outline-none focus:ring-2 focus:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus:ring-gray-300"
        href="/"
        >
          Go back home
        </a>
      </div>
    </div>
  );
}
