import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";

export const TABLE_COLUMNS = 
    {
        "staff": [
            {
                Header: "",
                accessor: "avatar",
            },
            {
                Header: "FirstName",
                accessor: "first_name",
            },
            {
                Header: "LastName",
                accessor: "last_name",
            },
            // {
            //     Header: "Status",
            //     accessor: "status",
            // },
            // {
            //     Header: "Risk Level",
            //     accessor: "risk_level",
            // },
            // {
            //     Header: "Groups",
            //     accessor: "groups"
            // },
        ],

        "history": [
            {
                Header: "User",
                accessor: "user",
            },
            {
                Header: "Log",
                accessor: "log",
            },
            {
                Header: "Time Stamp",
                accessor: "UpdatedAt",
                Cell: ({ value }) => {
                    return (
                        <span>
                            {new Date(value).toLocaleDateString()}
                        </span>
                    );
                }
            },
            {
                Header: "Flag",
                accessor: "flag",
                Cell: ({ value }) => {
                    return (
                        <Checkbox readOnly checked={value} />
                    );
                }
            },
        ],
    }
 