// src/context/SettingsContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const SettingsContext = createContext();

// Create a provider component
export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        eoi: { section1Width: 40, section2Width: 60 },
        vacancy: { section1Width: 50, section2Width: 50 },
        applicant: { section1Width: 30, section2Width: 70 },
        fontSize: '16px',
    });

    // Optionally, you can fetch settings from an API here
    useEffect(() => {
        // Example: Fetch settings from an API
        // fetchSettings().then(data => setSettings(data));
    }, []);

    useEffect(() => {
        document.documentElement.style.fontSize = settings.fontSize; // Apply font size globally
    }, [settings.fontSize]);

    return (
        <SettingsContext.Provider value={{ settings, setSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};

// Custom hook for using the settings context
export const useSettings = () => useContext(SettingsContext);
