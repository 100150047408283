import {  useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpApi } from "../../api/http.api";
import { notificationController } from "../../controllers/notificationController";
import {
    Check,
    Loader, X,
} from "lucide-react";
import { Separator } from "../../components/ui/separator";
import flagIcon from "../../assets/flag.svg"
import userFile from "../../assets/sidebar5.svg"
import { debounce } from "lodash-es";
import { useAutoAnimate } from '@formkit/auto-animate/react'

const Icon = ({ src, alt, className }) => (
    <img loading="lazy" src={src} alt={alt} className={className} />
);

const StatusIcon = ({ src, alt }) => (
    <img loading="lazy" src={src} alt={alt} className="aspect-[1.25] w-[30px] max-md:mt-10" />
);

const renderStatusContent = (index, id) => {
    if (index === 2) return <Loader />;
    if (index === 5) return <Loader />;
    if (id % 2 === 0) return <Check />;
    return <X />;
};

export default function VacancyList({filterDate , onSelectVacancy}) {
    const [parent, enableAnimations] = useAutoAnimate({
        // easing: 'ease-in-out',
        // disrespectUserMotionPreference: false
    })

    const navigate = useNavigate()

    const [selectedVacancy, setSelectedVacancy] = useState(null);
    const [vacancies, setVacancies] = useState([]);
    const [filteredVacancies, setFilteredVacancies] = useState([]);
    const {ID} = useParams();

    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        fetchVacancies(page);
    }, [page]);


    const fetchVacancies = debounce((page) => {
        if (hasMore) {
            setLoading(false)
            return;
        }
        setLoading(true)
        httpApi.get(`/vacancy?page=${page}`).then(response => {

            setVacancies((prevVacancies) => [...prevVacancies, ...response.data.data]);
            if (response.data.data.length === 0 || response.data.data.length < 10) {
                setHasMore(true)
            }
            console.log(response.data, "response")

            // using ID from URL to  select vacancy
            if (ID) {
                // check the id is in the list of vacancies
                const vacancy = response.data.data.find(vacancy => vacancy.id === ID)
                if (vacancy) {
                    setSelectedVacancy(vacancy)
                    onSelectVacancy(vacancy)
                } else {
                    notificationController.warning("The id Vacancy not found in the list")
                }
            }
        }).catch(error => {
            notificationController.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }, 100);


    const handleSelectVacancy = (vacancy) => {
        setSelectedVacancy(vacancy)
        onSelectVacancy(vacancy)
        navigate(`/vacancy/${vacancy.id}`)
    }

    const handleLoadMore = () => {
        const tableElement = document.getElementById("tableId");

        if (tableElement) {
            const {scrollTop, scrollHeight, clientHeight} = tableElement;

            console.log("Height:", scrollHeight);
            console.log("ScrollTop:", scrollTop);
            console.log("ClientHeight:", clientHeight);

            // Check if scrolled to the bottom
            if (scrollTop + clientHeight + 1 >= scrollHeight) {
                if (hasMore) return
                setLoading(true)
                // for see loading
                setTimeout(() => {
                    setPage((prevPage) => prevPage + 1);
                }, 500);
            }
        }
    };

    useEffect(() => {
        const tableElement = document.getElementById("tableId");

        if (tableElement) {
            tableElement.addEventListener("scroll", handleLoadMore);
        }

        return () => {
            if (tableElement) {
                tableElement.removeEventListener("scroll", handleLoadMore);
            }
        };
    }, []);

    useEffect(() => {

        // instead of filtering need to call api
        // fetchVacancies(page,date)
        const FilterDateRange = () => {
            const filtered = vacancies.filter((vacancy) => {
                const Thedate = new Date(vacancy.created_at);
                return Thedate >= filterDate.from && Thedate <= filterDate.to;
            });
            setFilteredVacancies(filtered);
            // setClassNamespl("animate-bounce");
        };

        FilterDateRange();
    }, [filterDate, vacancies]);

    return (

        <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
            <div
                className="flex flex-col items-start mt-1.5 max-md:mt-10 max-md:max-w-full overflow-auto max-h-[calc(100vh-165px)] pb-4  "
                id="tableId">
                <table className="w-full">
                    <thead className="text-xs sticky top-0 z-20 bg-gray-50">
                    <tr className="cursor-default select-none">
                        <th className="px-2 py-4 text-left pl-6">Designation | Location | VBC
                            No. | Date
                        </th>
                        <th className="px-2 py-4 text-center">Status</th>
                        <th className="px-2 py-4 text-center">Priority</th>
                        <th className="px-2 py-4 text-center">Pathway</th>
                    </tr>
                    <tr>
                        <td className={"pl-5 py-1"} colSpan={4}>
                            <Separator className="mb-1"/>
                        </td>
                    </tr>
                    </thead>
                    <tbody ref={parent}>
                    {filteredVacancies?.length > 0 && vacancies.map((vacancy, index) => (

                        <tr
                            key={index}
                            className={`cursor-pointer  ${selectedVacancy?.id === vacancy.id ? 'bg-white rounded-lg shadow-sm text-slate-600' : ''}`}
                            onClick={() => handleSelectVacancy(vacancy)}
                        >
                            <td className="px-2 py-4 pl-6">
                                <div
                                    className="text-base leading-6 text-slate-600">{vacancy.job_title}</div>
                                <div className="mt-2 text-xs">
                                    {vacancy.location} | {vacancy.id} | {vacancy.created_at}
                                </div>
                            </td>
                            <td className="px-2 py-4 text-center mx-auto ">
                                <div className="flex items-center justify-center text-gray-400">
                                    {renderStatusContent(index, vacancy.id)}
                                </div>

                            </td>
                            <td className="px-2 py-4 text-center">
                                {vacancy.id % 2 == 0 && (
                                    <div className="flex items-center justify-center">
                                        <img src={flagIcon} className="w-[18px]"/>
                                    </div>
                                )}

                            </td>
                            <td className="px-2 py-4 text-center">
                                <div className="flex items-center justify-center">
                                    {vacancy.id !== 14 && vacancy.id !== 10 ? (
                                        <StatusIcon
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5e659c2a7b5ccc8c4ea65c1fc895c48cacdf6f5198d24802c0dc493b2576087?apiKey=ae76636b06d44b88a66a58519c620a05&"
                                            alt="Status icon"
                                            className="mx-auto w-[18px]"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-center">
                                            <img src={userFile} className={"w-[18px]"}/>
                                        </div>
                                    )}
                                </div>

                            </td>
                            <td className="pr-4 py-4 text-center">
                                <div className="flex items-center justify-center">
                                    {selectedVacancy?.id === vacancy.id && (
                                        <Icon
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e42206b9408b9430811362523a56db063598d8d2c414ebfebe65af02830f66f8?apiKey=ae76636b06d44b88a66a58519c620a05&"
                                            alt="Status icon"
                                            className="shrink-0 my-auto aspect-square w-[18px]"
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    {loading && (
                        <tr className="flex items-center justify-end pr-6">
                            <td colSpan={4} className=" py-4 ">
                                <div className="mx-auto w-fit ">
                                    <Loader className={"animate-spin mx-auto "}/>
                                </div>
                            </td>
                        </tr>
                    )}
                    {hasMore && !loading && (
                        <tr>
                            <td colSpan={4} className="text-center py-4  ">
                                <span className="text-gray-400 mx-auto">No more data</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>


    )
}

