import userReducer from '../../store/slices/userSlice';
import authReducer from '../../store/slices/authSlice';
// import nightModeReducer from '@app/store/slices/nightModeSlice';
// import themeReducer from '@app/store/slices/themeSlice';
// import pwaReducer from '@app/store/slices/pwaSlice';

export default {
  user: userReducer,
  auth: authReducer,
  // nightMode: nightModeReducer,
  // theme: themeReducer,
  // pwa: pwaReducer,
};
