import { Link, useNavigate, useParams } from "react-router-dom";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import MyDataTable from "@/components/tables/MyDataTable";
import React, { useEffect, useState } from "react";
import { httpApi } from "@/api/http.api";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, } from "@/components/ui/sheet"
import { CameraIcon } from "lucide-react";
import { Label } from "../../components/ui/label";
import { notificationController } from "../../controllers/notificationController";
import MainLayout from "../../components/MainLayout";


const Icon = ({ src, alt, className }) => (
    <img loading="lazy" src={src} alt={alt} className={className} />
);

 function Content() {
    const navigate = useNavigate()
    const [filters, setFilters] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [groups, setGroups] = useState([])
    const { staffID } = useParams();
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isSheetOpen, setIsSheetOpen] = useState(false)

    function fetchGroups() {
        httpApi.get('/groups/all').then(response => {
            response.data = response.data.map(group => {
                return {
                    label: group.name, value: group.id,
                }
            })
            setGroups(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const addStaff = async () => {
        // create new team with api
        httpApi.post('/staff/create', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password
        }).then(response => {
            notificationController.success({ message: response.data.message });
            setIsSheetOpen(false)
            filters.push({ key: "staff", value: response.data.id })
        }).catch(err => {
            notificationController.error({ message: err.message });
        })
    }


    useEffect(() => {
    }, [])

    return (
        <Sheet open={isSheetOpen} onOpenChange={(value) => setIsSheetOpen(value)}>
            <div className="p-4 pb-0">

                        <div className="flex justify-between items-center mb-6">
                            <div className="flex space-x-3">
                                <Checkbox id="active" onCheckedChange={(checked) => {
                                    if (checked) {
                                        setFilters([...filters, { key: "status", value: "active" }])
                                    } else {
                                        setFilters(filters.filter(f => f.key !== "status"))
                                    }
                                }} />
                                <label className="text-sm font-medium" htmlFor="active">
                                    Active
                                </label>
                                <Checkbox id="inactive" onCheckedChange={(checked) => {
                                    if (checked) {
                                        setFilters([...filters, { key: "status", value: "inactive" }])
                                    } else {
                                        setFilters(filters.filter(f => f.key !== "status"))
                                    }
                                }} />
                                <label className="text-sm font-medium" htmlFor="inactive">
                                    Terminated
                                </label>
                            </div>
                            <Button onClick={() => setIsSheetOpen(true)}>Create new staff</Button>
                        </div>

                        <div className="flex justify-between items-center py-2 mb-2 ">
                            <Input className="w-1/4" placeholder="Search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        setFilters([{ key: "search", value: searchQuery }])
                                    }
                                }}
                                value={searchQuery}
                            />
                            <div className={"flex items-center "}>
                                <Select onValueChange={(value) => {
                                    if (value) {
                                        if (filters.find(f => f.key === "group")) {
                                            setFilters(filters.map(f => {
                                                if (f.key === "group") {
                                                    return { key: "group", value: value }
                                                }
                                                return f
                                            }))
                                        } else {
                                            setFilters([...filters, { key: "group", value: value }])
                                        }
                                    }
                                }}>
                                    <SelectTrigger id="sortGroups">
                                        <SelectValue placeholder="Groups" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {groups.map(group => (
                                                <SelectItem key={group.value}
                                                    value={group.value}>{group.label}</SelectItem>))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>

                        <div className={""}>
                            <MyDataTable tableName={"staff"} filters={filters} onClick={(id) => {
                                navigate("/staff/" + id)
                            }} />
                        </div>

            </div>


            <SheetContent className="w-1/2 !max-w-full">
                <SheetHeader>
                    <SheetTitle>Create Staff</SheetTitle>
                    <SheetDescription>
                        <div className={""}>
                            <div className="grid grid-cols-3 gap-8">
                                <div className="col-span-2 space-y-6">
                                    <div className="flex space-x-4">
                                        <div
                                            className="flex items-center p-4 w-full justify-center border border-gray-300 rounded-lg">
                                            <div
                                                className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100">
                                                <CameraIcon className="text-gray-500" />
                                            </div>
                                            <div className="flex flex-col flex-grow ml-4">
                                                <span className="text-sm text-gray-500">JPG, PNG Max size of 12mb</span>
                                                <input
                                                    accept="image/jpeg, image/png"
                                                    className="w-full h-10 text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                                                                 file:rounded-full file:border-0 file:text-sm file:font-semibold
                                                               file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                                                    type="file"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <h2 className="text-xl font-semibold">Staff Details</h2>
                                        <div className="space-y-2">
                                            <Label htmlFor="title">Title</Label>
                                            <Select>
                                                <SelectTrigger id="title">
                                                    <SelectValue placeholder="Select preferred title" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="mr">Mr.</SelectItem>
                                                    <SelectItem value="ms">Ms.</SelectItem>
                                                    <SelectItem value="mrs">Mrs.</SelectItem>
                                                    <SelectItem value="dr">Dr.</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="first-name">First name*</Label>
                                            <Input id="first-name" placeholder="e.g. Annabelle"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="middle-name">Middle name</Label>
                                            <Input id="middle-name" placeholder="e.g. Louise" />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="last-name">Last name*</Label>
                                            <Input id="last-name" placeholder=""
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="last-name">Email</Label>
                                            <Input id="last-name" placeholder=""
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="last-name">Password</Label>
                                            <Input id="last-name" placeholder=""
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <aside className="space-y-4">
                                    <div className="text-sm font-semibold text-gray-600">Personal details</div>
                                    <nav className="space-y-2">
                                        <Link className="block p-2 rounded hover:bg-gray-100" href="#">
                                            MyProfilePage
                                        </Link>
                                        <Link className="block p-2 rounded hover:bg-gray-100" href="#">
                                            Contact details
                                        </Link>
                                        <Link className="block p-2 rounded hover:bg-gray-100" href="#">
                                            Highlights
                                        </Link>
                                    </nav>
                                </aside>
                            </div>

                            <div className="flex justify-end mt-6">
                                <Button className="bg-blue-600 text-white" onClick={addStaff}>Submit</Button>
                            </div>
                        </div>
                    </SheetDescription>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}

export default function StaffsPage(){
    return(
        <>
            <MainLayout title={"Staffs"} childTwo={<Content />} />
        </>
    )
}

