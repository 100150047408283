import axios, {AxiosError} from 'axios';
import { ApiError } from './ApiError';
import {readToken} from '../services/localStorage.service';

export const httpApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config: any) => {
    config.headers = {...config.headers, Authorization: `Bearer ${readToken()}`};

    return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
    // its refactored, check original function
    if (typeof error.response?.data === 'object' && error.response?.data !== null) {
        const data = error.response.data as ApiErrorData;
        throw new ApiError<ApiErrorData>(data.message || error.message, data);
    } else {
        throw new ApiError<ApiErrorData>(error.message, undefined);
    }
});

export interface ApiErrorData {
    message: string;
}
