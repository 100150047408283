import {ChevronDown} from "lucide-react";


export  default  function CountCard({index,icon , name, count}){
    return (
        <div key={index}
             className="border mx-auto rounded-md p-6 flex flex-col items-center justify-start w-fit gap-3 transition-all ease-in-out hover:bg-blue-100 cursor-pointer group hover:border-blue-300">
            <div
                className="border rounded-full w-24 h-24 flex items-center justify-center group-hover:bg-white transition-colors">
                <span className="text-4xl text-gray-500 group-hover:text-blue-500 transition-colors">
                {icon}
                </span>
            </div>
            <div className="text-4xl font-bold">
                {count}
            </div>
            <div className="w-28">
                <p className="text-gray-500 font-semibold text-wrap text-center">
                    {name}
                </p>
            </div>
            <hr className={"h-[3px] bg-gray-400 w-1/3"}/>
            <div className="bg-blue-400 rounded-full flex items-center justify-center p-0.5">
                <ChevronDown className="max-w-[15px] max-h-[15px] text-white"/>
            </div>
        </div>
    )
}