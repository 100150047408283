import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import {CalendarIcon, CircleX, UserRound  , Image } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Calendar } from "@/components/ui/calendar";
import { Switch } from "@/components/ui/switch";
import {Checkbox, Input as MInput} from "@material-tailwind/react";
import { Select as MSelect, Option } from "@material-tailwind/react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { httpApi } from "../../../../api/http.api";
import { notificationController } from "../../../../controllers/notificationController";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import HistoryLog from "@/components/historyLog";
import { Radio } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}


const formSchema = z.object({
    general: z.object({
        image: z.string().nonempty(),
        first_name: z.string().nonempty(),
        middle_name: z.string().nonempty(),
        last_name: z.string().nonempty(),
        alias: z.string().nonempty(),
        mobile_number: z.string().nonempty(),
        whatsapp_number: z.string().nonempty(),
        gender: z.string().nonempty(),
        alternative_number: z.string().nonempty(),
        email_id: z.string().nonempty(),
        date_of_birth: z.date().nonempty,
    }),
    address: z.object({
        permenent_address: z.object({
            address_line_1: z.string().nonempty(),
            address_line_2: z.string().nonempty(),
            address_line_3: z.string().nonempty(),
            post_code: z.string().nonempty(),
            city: z.string().nonempty(),
            country: z.string().nonempty(),
            w3w: z.string().nonempty(),
            google_coordinates: z.string().nonempty(),
        }),
        current_address: z.object({
            address_line_1: z.string().nonempty(),
            address_line_2: z.string().nonempty(),
            address_line_3: z.string().nonempty(),
            post_code: z.string().nonempty(),
            city: z.string().nonempty(),
            country: z.string().nonempty(),
            w3w: z.string().nonempty(),
            google_coordinates: z.string().nonempty(),
        }),
    }),
    next_of_kin: z.object({
        first_name: z.string().nonempty(),
        last_name: z.string().nonempty(),
        relationship: z.string().nonempty(),
        address_line_1: z.string().nonempty(),
        address_line_2: z.string().nonempty(),
        city: z.string().nonempty(),
        country: z.string().nonempty(),
        mobile_number: z.string().nonempty(),
        alternative_number: z.string().nonempty(),
        email_id: z.string().nonempty(),
    }),
    branch: z.object({
        zone: z.string().nonempty(),
        staff_group: z.string().nonempty(),
        staff_tier: z.string().nonempty(),
    }),
});


export default function PersonalTab() {
    const { toast } = useToast();
    const { ID } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(1);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    useEffect(() => {
        if (ID) {
            setLoading(true);
            setIsEdit(true);
            // fetchVacancy();
        }
    }, [ID]);

    const fetchVacancy = async () => {
        try {
            // const response = await httpApi.get(`/vacancy/${ID}`);
            // setVacancy(response.data);
            setIsEdit(true);
            // set the form values
            // console.log(response.data);

        } catch (error) {
            notificationController.error(error);
        } finally {
            setLoading(false);
        }
    };

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            general: {
                image: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                alias: "",
                mobile_number: "",
                whatsapp_number: "",
                date_of_birth: "",
                gender: "",
                alternative_number: "",
                email_id: "",
            },
            address: {
                permenent_address: {
                    address_line_1: "",
                    address_line_2: "",
                    address_line_3: "",
                    post_code: "",
                    city: "",
                    country: "",
                    w3w: "",
                    google_coordinates: "",
                },
                current_address: {
                    address_line_1: "",
                    address_line_2: "",
                    address_line_3: "",
                    post_code: "",
                    city: "",
                    country: "",
                    w3w: "",
                    google_coordinates: "",
                },
            },
            next_of_kin: {
                first_name: "",
                last_name: "",
                relationship: "",
                address_line_1: "",
                address_line_2: "",
                city: "",
                country: "",
                mobile_number: "",
                alternative_number: "",
                email_id: "",
            },
            branch: {
                zone: "",
                staff_group: "",
                staff_tier: "",
            }

        }
    });

    async function onSubmit(values) {
        try {
            console.log(values);
            const data = {
                ...values,
            };

        } catch (error) {
            console.error(error);
        }
    }

    const Genders = [
        { value: 'male' , label: 'Male' },
        { value: 'female' , label: 'Female' },
        { value: 'other' , label: 'Other' },
    ];
    const Relationship = [
        { value: 'father' , label: 'Father' },
        { value: 'mother' , label: 'Mother' },
        { value: 'sibling' , label: 'Sibling' },
        { value: 'spouse' , label: 'Spouse' },
        { value: 'child' , label: 'Child' },
        { value: 'friend' , label: 'Friend' },
        { value: 'other' , label: 'Other' },
    ];
    const Country = [
        { value: 'india' , label: 'India' },
        { value: 'usa' , label: 'USA' },
        { value: 'uk' , label: 'UK' },
        { value: 'australia' , label: 'Australia' },
        { value: 'canada' , label: 'Canada' },
        { value: 'other' , label: 'Other' },
    ];

    const Staff_groups = [
        { value: 'group_1' , label: 'Group 1' },
        { value: 'group_2' , label: 'Group 2' },
        { value: 'group_3' , label: 'Group 3' },
        { value: 'group_4' , label: 'Group 4' },
        { value: 'group_5' , label: 'Group 5' },
    ];

    const Staff_tier = [
        { value: 'tier_1' , label: 'Tier 1' },
        { value: 'tier_2' , label: 'Tier 2' },
        { value: 'tier_3' , label: 'Tier 3' },
        { value: 'tier_4' , label: 'Tier 4' },
        { value: 'tier_5' , label: 'Tier 5' },
        ];

    const Zone = [
        { value: 'zone_A' , label: 'Zone A' },
        { value: 'zone_B' , label: 'Zone B' },
        { value: 'zone_C' , label: 'Zone C' },
        { value: 'zone_D' , label: 'Zone D' },
        { value: 'zone_E' , label: 'Zone E' },
        ];
    const onerror = (error) => {
        console.log(error);
    }

    const handleSameAddress = () => {
        if (document.getElementById('same-address').checked) {
            form.setValue('address.current_address', form.getValues('address.permenent_address'));
        }else {
            form.setValue('address.current_address', {
                address_line_1: "",
                address_line_2: "",
                address_line_3: "",
                post_code: "",
                city: "",
                country: "",
                w3w: "",
                google_coordinates: "",
            });
        }
    };
    return (
        <>
            <div className="  p-2 ">
                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <div className="loader">Loading...</div>
                    </div>
                ) : (
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit,onerror)} className="">
                                <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                    <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 !text-lg">General</AccordionHeader>
                                    <AccordionBody >
                                        <section className="space-y-6 pb-6">
                                            <div className="flex gap-8">
                                                <div
                                                    className="w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center cursor-pointer">
                                                    <Image className="text-gray-500  w-10 h-10"/>
                                                </div>
                                                <div
                                                    className="grid gap-6 flex-1 max-w-[calc(200px * 3 + 6px * 2)]"
                                                    style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}
                                                >
                                                    <FormField
                                                        control={form.control}
                                                        name="first_name"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    {/*<Input*/}
                                                                    {/*  placeholder="Proposed Job Title"*/}
                                                                    {/*  custom={true}*/}
                                                                    {/* */}
                                                                    {/*/>*/}

                                                                    <MInput {...field} variant="standard"
                                                                            label="First Name"
                                                                            placeholder="First Name"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="middle_name"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Middle Name"
                                                                            placeholder="Middle Name"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="last_name"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Last Name"
                                                                            placeholder="Last Name"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="alias"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Alias"
                                                                            placeholder="Alias"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="mobile_number"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Mobile number"
                                                                            placeholder="Mobile number"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="whatsapp_number"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Whatsapp number"
                                                                            placeholder="Whatsapp number"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />

                                                    <FormField
                                                        control={form.control}
                                                        name="gender"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MSelect {...field} variant="standard"
                                                                             label="Gender">
                                                                        {Genders.map((option) => (
                                                                            <Option
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                {option.label}
                                                                            </Option>
                                                                        ))}
                                                                    </MSelect>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="alternative_number"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Alternative no."
                                                                            placeholder="Alternative no."/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="email_id"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <MInput {...field} variant="standard"
                                                                            label="Email ID"
                                                                            placeholder="Email ID"/>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="date_of_birth"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <Popover>
                                                                        <PopoverTrigger asChild>
                                                                            <FormControl>
                                                                                <Button
                                                                                    variant={"outline"}
                                                                                    className={cn(
                                                                                        "w-full pl-3 text-left font-normal border-0 border-b-2 shadow-none rounded-none border-gray-400 place-self-end mt-2",
                                                                                        !field.value && "text-muted-foreground",
                                                                                    )}
                                                                                >
                                                                                    {field.value ? (
                                                                                        format(field.value, "PPP")
                                                                                    ) : (
                                                                                        <span>DOB</span>
                                                                                    )}
                                                                                    <CalendarIcon
                                                                                        className="ml-auto h-4 w-4 opacity-50"/>
                                                                                </Button>
                                                                            </FormControl>
                                                                        </PopoverTrigger>
                                                                        <PopoverContent
                                                                            className="w-auto p-0"
                                                                            align="start"
                                                                        >
                                                                            <Calendar
                                                                                mode="single"
                                                                                selected={field.value}
                                                                                onSelect={field.onChange}
                                                                                disabled={(date) =>
                                                                                    date > new Date() ||
                                                                                    date < new Date("1900-01-01")
                                                                                }
                                                                                initialFocus
                                                                            />
                                                                        </PopoverContent>
                                                                    </Popover>
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                        </section>
                                    </AccordionBody>
                                </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open}/>}>
                                <AccordionHeader className="border-t-2 border-b-0 !text-lg"
                                                 onClick={() => handleOpen(2)}>
                                    Address
                                </AccordionHeader>
                                <AccordionBody>
                                    <section className="space-y-6 pb-6">
                                        <div className="flex items-center justify-start gap-3 font-semibold">
                                            Permenent Address
                                        </div>
                                        <div
                                            className="grid gap-6 flex-1 max-w-[calc(200px * 3 + 6px * 2)]"
                                            style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}
                                        >
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.address_line_1"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 1"
                                                                    placeholder="Address line 1"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.address_line_2"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 2"
                                                                    placeholder="Address line 2"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.address_line_3"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 3"
                                                                    placeholder="Address line 3"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.post_code"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Post code"
                                                                    placeholder="Post code"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.w3w"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="W3W"
                                                                    placeholder="W3W"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.city"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="City"
                                                                    placeholder="City"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permenent_address.google_coordinates"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Google Coordinates"
                                                                    placeholder="Google Coordinates"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.permanent_address.country"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MSelect {...field} variant="standard"
                                                                     label="Country">
                                                                {Country.map((option) => (
                                                                    <Option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </Option>
                                                                ))}
                                                            </MSelect>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>


                                        <div className="flex items-center justify-start gap-3 font-semibold">
                                            Current Address <Checkbox id={"same-address"} onChange={handleSameAddress} /> <p>Same as Permenent Address</p>
                                        </div>
                                        <div
                                            className="grid gap-6 flex-1 max-w-[calc(200px * 3 + 6px * 2)]"
                                            style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}
                                        >
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.address_line_1"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 1"
                                                                    placeholder="Address line 1"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.address_line_2"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 2"
                                                                    placeholder="Address line 2"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.address_line_3"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Address line 3"
                                                                    placeholder="Address line 3"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.post_code"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Post code"
                                                                    placeholder="Post code"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.w3w"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="W3W"
                                                                    placeholder="W3W"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.city"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="City"
                                                                    placeholder="City"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.google_coordinates"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Google Coordinates"
                                                                    placeholder="Google Coordinates"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="address.current_address.country"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MSelect {...field} variant="standard"
                                                                     label="Country">
                                                                {Country.map((option) => (
                                                                    <Option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </Option>
                                                                ))}
                                                            </MSelect>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>

                                    </section>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open}/>}>
                                <AccordionHeader className="border-t-2 border-b-0 !text-lg"
                                                 onClick={() => handleOpen(3)}>
                                    Next of Kin
                                </AccordionHeader>
                                <AccordionBody>
                                    <div
                                        className="grid gap-6 flex-1 max-w-[calc(200px * 3 + 6px * 2)] pb-6"
                                        style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}
                                    >
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.first_name"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="First Name"
                                                                placeholder="First Name"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.last_name"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Last Name"
                                                                placeholder="Last Name"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.relationship"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MSelect {...field} variant="standard"
                                                                 label="Relationship">
                                                            {Relationship.map((option) => (
                                                                <Option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </MSelect>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.country"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MSelect {...field} variant="standard"
                                                                 label="Country">
                                                            {Country.map((option) => (
                                                                <Option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </MSelect>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.address_line_1"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Address line 1"
                                                                placeholder="Address line 1"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.address_line_2"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Address line 2"
                                                                placeholder="Address line 2"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.email_id"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Email ID"
                                                                placeholder="Email ID"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.city"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="City"
                                                                placeholder="City"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.mobile_number"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Mobile number"
                                                                placeholder="Mobile number"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="next_of_kin.alternative_number"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Alternative number"
                                                                placeholder="Alternative number"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open}/>}>
                                <AccordionHeader className="border-t-2 border-b-0 !text-lg"
                                                 onClick={() => handleOpen(4)}>
                                    Branch
                                </AccordionHeader>
                                <AccordionBody>
                                    <section className="space-y-6">
                                        <FormField
                                            control={form.control}
                                            name="branch.zone"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel>Zone</FormLabel>
                                                    <FormControl>
                                                        <div className="flex w-max gap-4">
                                                            {Zone.map((option) => (
                                                                <Radio
                                                                    color={"blue"}
                                                                    key={option.value}
                                                                    value={option.value}
                                                                    label={option.label}
                                                                    {...field}
                                                                />
                                                            ))}
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="branch.staff_group"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel>Staff group</FormLabel>
                                                    <FormControl>
                                                        <div className="flex w-max gap-4">
                                                            {Staff_groups.map((option) => (
                                                                <Radio
                                                                    color={"blue"}
                                                                    key={option.value}
                                                                    value={option.value}
                                                                    label={option.label}
                                                                    {...field}
                                                                />
                                                            ))}
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="branch.staff_tier"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel>Staff tier</FormLabel>
                                                    <FormControl>
                                                        <div className="flex w-max gap-4">
                                                            {Staff_tier.map((option) => (
                                                                <Radio
                                                                    color={"blue"}
                                                                    key={option.value}
                                                                    value={option.value}
                                                                    label={option.label}
                                                                    {...field}
                                                                />
                                                            ))}
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </section>

                                </AccordionBody>

                            </Accordion>
                            <div className="border-t-2 border-b-0"></div>
                        </form>
                    </Form>
                )}
                <HistoryLog modelName={"eoi"} modelID={10}/>

            </div>
        </>
    );
}
