import {
    BookUserIcon,
    ClipboardListIcon,
    LayoutDashboard,
    LifeBuoy,
    MessageSquareIcon,
    SearchIcon,
    Settings,
    SmileIcon
} from "lucide-react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useTheme} from "@/components/theme-provider"
import {useAppSelector} from "@/hooks/reduxHooks";
import {QueryClient, QueryClientProvider} from "react-query";
import React from "react";
import Sidebar, {SidebarItem} from "./SideBar";

const Divider = ({className}) => (
    <div className={`shrink-0 w-px border border-solid ${className}`}/>
);

const Icon = ({src, alt, className}) => (
    <img loading="lazy" src={src} alt={alt} className={className}/>
);

export default function NavBar() {
    const {pathname} = useLocation();
    const user = useAppSelector((state) => state.user.user);
    const queryClient = new QueryClient()

    return (
        <div className="min-h-[100vh] ">
            <header className="flex flex-col pt-3 w-full shadow-sm bg-neutral-700 max-md:max-w-full dark:bg-gray-950">
                <div
                    className="flex gap-5 justify-between items-center self-center px-10 w-full  max-md:flex-wrap max-md:max-w-full ">
                    <div className="flex gap-5 self-stretch my-auto text-xs leading-5 text-slate-600">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c5a4aef61cf1b5ee5885e4c70a8baeea07f76475c566078cf5681ee3ffe1c85?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Company logo" className="shrink-0 aspect-[4] w-[73px]"/>
                        <Divider className="bg-slate-600 border-slate-600 h-[18px] "/>
                        <div className="flex-auto my-auto">Your HR Hub. Simplified!</div>
                    </div>
                    <form
                        className="flex gap-2.5 self-stretch px-3.5 py-3 text-sm font-medium leading-5 whitespace-nowrap bg-white border border-solid border-slate-200 rounded-[34px] text-slate-600 max-w-[500px] flex-1 dark:bg-gray-950">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/01423d2ef7c685077346615b78c832ab6fa117eb9018ce1fce9d2b98f923da46?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Search icon" className="shrink-0 w-3.5 aspect-square fill-slate-600"/>
                        <label htmlFor="searchInput" className="sr-only">Search</label>
                        <input id="searchInput" type="text" placeholder="Search"
                               className="flex-auto my-auto bg-transparent border-none outline-none "/>
                    </form>
                    <nav className="flex gap-3 self-stretch my-auto">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ceb5efce947d11ed31894b717e28b53cea48cb995073fae0bb38f0ecbe92311a?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Notification icon" className="shrink-0 aspect-square w-[18px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3cbdeb3d90ac01717e378a285cac0dc55f5654a68204382aed5cd916b007d1cf?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Messages icon" className="shrink-0 aspect-[0.83] fill-slate-600 w-[15px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce0b0d783a7f5435d21cf8b00a9b3a59d7263e92117f876e6f29596ebf74214?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="User profile icon" className="shrink-0 self-start aspect-[1.06] w-[18px]"/>
                    </nav>
                </div>
                <div
                    className="mt-2.5 w-full bg-gray-200 border border-gray-200 border-solid min-h-[1px] max-md:max-w-full dark:border-gray-800"/>
            </header>

            <main className="flex gap-0  max-md:flex-wrap ">
                {/*<SideBar/>*/}
                <Sidebar>
                    <Link to={"/chat"}>
                        <SidebarItem icon={1} text="Chat Space"  active={pathname === "/chat"}/>
                    </Link>
                    <Link to={"/dashboard"}>
                        <SidebarItem icon={2} text="Dashboard"
                                     active={pathname === "/dashboard"}/>
                    </Link>
                    <Link to={"/vacancy"}>
                        <SidebarItem icon={3} text="Vacancy" alert
                                     active={pathname.startsWith("/vacancy")}/>
                    </Link>
                    <Link to={"/eoi"}>
                        <SidebarItem icon={4} text="Expression"
                                     active={pathname.startsWith("/eoi")}/>
                    </Link>
                    <Link to={"/applicant"}>
                        <SidebarItem icon={5} text="Applicant"
                                     active={pathname.startsWith("/applicant")}/>
                    </Link>
                    <Link to={"/staff"}>
                        <SidebarItem icon={6} text="Staff"
                                     active={pathname.startsWith("/staff")}/>
                    </Link>
                    {/*<SidebarItem icon={<Flag size={20} />} text="Reporting" />*/}
                    <hr className=""/>
                    <Link to={"/settings"}>
                        <SidebarItem icon={9} text="Settings" active={pathname.startsWith("/settings")}/>
                    </Link>
                    {/*<SidebarItem icon={8} text="Log out"/>*/}
                    {/*<SidebarItem icon={7} text="Help" active={pathname === "/Help"}/>*/}
                </Sidebar>
                <div className="w-full h-[calc(100vh-71px)] dark:bg-slate-400  ">
                    <QueryClientProvider client={queryClient}>
                        <Outlet/>
                    </QueryClientProvider>
                </div>

            </main>

        </div>
    )
}
