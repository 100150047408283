import React, {useState} from 'react';
import MainLayout from "../components/MainLayout";
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from "@material-tailwind/react";
import SettingsRolePage from "./settings/tabs/RolesPage";
import SettingsUsersPage from "./settings/tabs/SettingsUsersPage";
import SettingsPreferencePage from "./settings/tabs/PreferencePage";

export default function SettingsPage() {
    const tabs = [
        {
            label: "Users",
            value: "users",
            Component: SettingsUsersPage
        },
        {
            label: "Roles",
            value: "roles",
            Component: SettingsRolePage
        },
        {
            label: "Preference",
            value: "preference",
            Component: SettingsPreferencePage
        }
    ];
    const [activeTab, setActiveTab] = useState("personal");

    return (
        <MainLayout title={"Settings"}
                    childTwo={
                        <Tabs value="roles">
                            <TabsHeader
                                indicatorProps={{
                                    className: "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                                }}
                            >
                                {tabs.map(({label, value}) => (
                                    <Tab
                                        key={value}
                                        value={value}
                                        onClick={() => setActiveTab(value)}
                                        className={activeTab === value ? "text-gray-900" : ""}
                                    >
                                        {label}
                                    </Tab>
                                ))}
                            </TabsHeader>
                            <TabsBody>
                                {tabs.map(({value, Component}) => (
                                    <TabPanel key={value} value={value}>
                                        <Component  />
                                    </TabPanel>
                                ))}
                            </TabsBody>
                        </Tabs>
                    }/>
    )

}
