import { Link, Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    CopyPlusIcon,
    ListTodo,
    LogOutIcon,
    Plus,
    SlidersHorizontal,
    UsersIcon,
    CalendarDays,
    Calendar as CalendarIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Checkbox } from "@/components/ui/checkbox"
import { Calendar } from "../../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Switch } from "@/components/ui/switch"
import { Separator } from "../../components/ui/separator";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import {addDays, format} from "date-fns";
import { cn } from "@/lib/utils"
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/components/ui/resizable"
import MainLayout from "../../components/MainLayout";
import VacancyList from "./VacancyList";
import {useSettings} from "../../context/SettingsContext";

const Divider = ({ className }) => (
    <div
        className={`shrink-0 w-px border border-solid bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[20px] ${className}`} />
);

const Icon = ({ src, alt, className }) => (
    <img loading="lazy" src={src} alt={alt} className={className} />
);



const Header = ({ date, setDate }) => {
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const [dialogOpen, setDialogOpen] = useState(false)
    const [isReplacement, setIsReplacement] = useState(false)

    const [showStatusBar, setShowStatusBar] = useState(false)
    const [classNamespl, setClassNamespl] = useState("")


    function handleDialog() {
        if (dialogOpen) {
            setDialogOpen(false)
        } else {
            setDialogOpen(true)
            // setSelectedVacancy(null)
        }
    }

    const handleTemplate = () => {
        navigate("/vacancy/templates")
    }

    const handleNavigate = (path) => {
        navigate(path)
    }
    const handleVacancyNew = () => {
        // console.log(isReplacement, "hhh")
        if (isReplacement) {
            navigate("/vacancy/replacement")
        } else {
            navigate("/vacancy/new")
        }
        setDialogOpen(false)
        return (
            <Navigate to={"/vacancy/new"}></Navigate>
        )
    }

    return (
        <>
        <div
            className="flex gap-5 justify-end items-start  bg-white ">
            {/*<h1 className="flex gap-4 px-5 text-2xl font-medium leading-8 text-slate-600 items-center">*/}
            {/*    <HasAccess*/}
            {/*        roles={["admin"]}*/}
            {/*        permissions={["admin"]}*/}
            {/*        // renderAuthFailed={<p>You are not authorized to access!</p>}*/}
            {/*    >*/}
            {/*        <button type="button"> Authorized</button>*/}
            {/*    </HasAccess>*/}
            {/*    <span className="flex-auto font-bold">Vaccancy Business Case</span>*/}
            {/*</h1>*/}
            <div className="flex gap-3 px-5">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <div variant="outline" className="">
                            <SlidersHorizontal
                                className="shrink-0 self-start aspect-[1.12] w-[18px] cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel className="flex gap-2 items-center">
                            <SlidersHorizontal className="w-[16px]"/>
                            <span className="font-bold">VBC Filter</span>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator/>
                        <div className=" p-2">
                            <h3 className="text-blue-500 font-semibold">
                                Status Filter
                            </h3>
                            <div className="p-2 space-y-2">
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        New VBC
                                    </label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        Progressing
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className=" p-2">
                            <h3 className="text-blue-500 font-semibold">
                                Job Type Filter
                            </h3>
                            <div className="p-2 space-y-2">
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        New VBC
                                    </label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        Progressing
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 p-2">
                            <h3 className="text-blue-500 font-semibold">
                                Location Filter
                            </h3>
                            <div className="p-2 space-y-2">
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        New VBC
                                    </label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="terms" checked={showStatusBar}
                                              onCheckedChange={setShowStatusBar}/>
                                    <label
                                        htmlFor="terms"
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                        Progressing
                                    </label>
                                </div>
                            </div>
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>

                <Divider/>
                <Icon
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/258654b4dc9cc29c4f5baf02032b98698963384b4522aff366021aadd20a99db?apiKey=ae76636b06d44b88a66a58519c620a05&"
                    alt="Sort icon" className="shrink-0 aspect-[1.06] w-[18px] cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
                <Divider/>
                <div className={cn("grid gap-2", classNamespl)}>
                    <Popover>
                        <PopoverTrigger asChild>
                            <CalendarDays className="w-[16px] cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
                            {/*<CalendarIcon className="mr-2 h-4 w-4"/>*/}

                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 m-2" align="start">
                            <div className="flex items-center jusitfy-center mt-2">
                                <Button
                                    id="date"
                                    disabled={true}
                                    variant={"outline"}
                                    className={cn(
                                        "w-[300px] justify-start text-left font-normal mx-auto",
                                        !date && "text-muted-foreground"
                                    )}
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4"/>
                                    {date?.from ? (
                                        date.to ? (
                                            <>
                                                {format(date.from, "LLL dd, y")} -{" "}
                                                {format(date.to, "LLL dd, y")}
                                            </>
                                        ) : (
                                            format(date.from, "LLL dd, y")
                                        )
                                    ) : (
                                        <span>Pick a date</span>
                                    )}
                                </Button>
                            </div>
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                            {/*<div className="w-full flex items-center justify-center">*/}
                            {/*    <Button onClick={() => FilterDateRange()} className="w-[60%] m-4 mx-auto">Apply</Button>*/}
                            {/*</div>*/}
                        </PopoverContent>
                    </Popover>
                </div>
                <Divider/>
                <Link>
                    <div onClick={handleDialog}>
                        <Plus
                            className="shrink-0 self-start text-gray-500 w-5 cursor-pointer text-sm text-neutral-400 hover:text-black active:text-black focus:text-black"
                        />
                    </div>
                    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                        <DialogContent className="sm:max-w-[425px] p-8 pt-10 border">
                            <DialogHeader className="pb-6">
                                <DialogTitle>
                                </DialogTitle>
                                <div className="flex items-center space-x-2 font-semibold justify-center mt-6">
                                    <span>New Post Vacancy</span>
                                    <Switch
                                        id="airplane-mode"
                                        checked={isReplacement}
                                        onCheckedChange={(value) => setIsReplacement(value)}
                                    />
                                    <span>Replacement Vacancy</span>
                                </div>
                            </DialogHeader>
                            <div className="flex flex-col gap-6 p-4 px-8">
                                <Link to={isReplacement ? "/vacancy/replacement" : "/vacancy/new"}>
                                    <Button
                                        onClick={() => handleVacancyNew()}
                                        variant="outline"
                                        className="rounded-full bg-gray-200 !px-8 border border-gray-500 font-bold w-full"
                                    >
                                        Create New
                                    </Button>
                                </Link>
                                <Button
                                    onClick={() => handleNavigate('/vacancy/templates')}
                                    variant="outline"
                                    className="rounded-full bg-gray-200 !px-8 border border-gray-500 font-bold"
                                >
                                    Choose from Template/Drafts
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Link>
                <Divider/>
                <div onClick={handleTemplate}>
                    <ListTodo
                        className={`shrink-0 self-start  w-[18px] cursor-pointer text-sm text-neutral-400 hover:text-black active:text-black focus:text-black ${pathname === "/vacancy/templates" ? "text-gray-800" : "text-gray-500"}`}/>
                </div>
            </div>

        </div>
        </>
    )
};


function Content({ date }) {
    const {settings} =useSettings();
    const [parent, enableAnimations] = useAutoAnimate({
        // easing: 'ease-in-out',
        // disrespectUserMotionPreference: false
    })
    const {pathname} = useLocation();
    const [selectedVacancy, setSelectedVacancy] = useState(null);

    const handleSelectVacancy = (vacancy) => {
        setSelectedVacancy(vacancy);
    };

    return (
        <div className="max-md:max-w-full">
                <ResizablePanelGroup direction="horizontal" className="grid grid-cols-2 gap-4 ">
                    <ResizablePanel defaultSize={settings.vacancy.section1Width}>
                       <VacancyList filterDate={date} onSelectVacancy={handleSelectVacancy} />
                    </ResizablePanel>
                    <ResizableHandle withHandle/>
                    <ResizablePanel  defaultSize={settings.vacancy.section2Width}>
                        <div className="flex flex-col  px-3 w-full max-h-[calc(100vh-165px)] pb-4 overflow-auto mt-2">
                            <div
                                className={`flex flex-col grow max-md:mt-10 max-md:max-w-full w-full  ${selectedVacancy !== null ? '' : ''}`}>
                                <div className=" sticky top-0 z-20 bg-gray-50">

                                    {selectedVacancy !== null && (
                                        <>
                                    <div className="w-full flex gap-3 justify-end items-center mt-2.5">
                                        <Link to={`/vacancy/${selectedVacancy?.id}`}>
                                            <CopyPlusIcon
                                                className={`shrink-0 w-5 ${pathname === "/vacancy/" + selectedVacancy?.id ? "text-gray-800" : "text-gray-500"}`}/>
                                        </Link>
                                        <Divider className={` h-5`}/>

                                        <Link to={`/vacancy/${selectedVacancy?.id}/applicants`}>
                                            <UsersIcon
                                                className={`shrink-0 w-5 ${pathname.includes("/vacancy/") && pathname.includes("/applicants") ? "text-gray-800" : "text-gray-500"} `}
                                            />
                                        </Link>
                                        <Divider className=" h-5"/>
                                        <Link to={`/vacancy/${selectedVacancy?.id}/shortlisted`}>
                                            <LogOutIcon
                                                className={`shrink-0 w-5 ${pathname.includes("/vacancy/") && pathname.includes("/shortlisted") ? "text-gray-800" : "text-gray-500"}`}
                                            />
                                        </Link>
                                        {/*<div className='space-x-4 flex items-center'>*/}
                                        {/*    <Select>*/}
                                        {/*        <SelectTrigger*/}
                                        {/*            className="w-[100px] font-bold rounded-full !h-8 bg-gray-50 text-sm  border border-gray-400 text-gray-500 !outline-none !ring-0">*/}
                                        {/*            <SelectValue placeholder="Status" />*/}
                                        {/*        </SelectTrigger>*/}
                                        {/*        <SelectContent>*/}
                                        {/*            <SelectGroup>*/}
                                        {/*                <SelectItem value="apple">Apple</SelectItem>*/}
                                        {/*                <SelectItem value="banana">Banana</SelectItem>*/}
                                        {/*                <SelectItem value="blueberry">Blueberry</SelectItem>*/}
                                        {/*            </SelectGroup>*/}
                                        {/*        </SelectContent>*/}
                                        {/*    </Select>*/}
                                        {/*    <Button variant="outline"*/}
                                        {/*        className="rounded-full bg-gray-200 !px-8 border border-gray-400 text-gray-600 font-bold !py-1 !h-8">Update</Button>*/}
                                        {/*</div>*/}
                                    </div>
                                        </>
                                )}
                                    {selectedVacancy === null && (
                                        <div className="mt-[50px]" />
                                    )}
                                    <Separator className={"mt-4  mb-3 "}/>

                                </div>
                                <div className="pt-2" ref={parent}>
                                    <Outlet/>
                                </div>
                            </div>
                        </div>
                    </ResizablePanel>
                </ResizablePanelGroup>
        </div>
    )
}


export default function VacanciesPage() {
    const [date, setDate] = useState({
        from: new Date(2024, 6, 20),
        to: addDays(new Date(2024, 7, 20), 20),
    });
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/vacancy?from=${format(date.from, "yyyy-MM-dd")}&to=${format(date.to, "yyyy-MM-dd")}`);
    }, [date]);

    return (
        <MainLayout
            title={"Vaccancy Business Case"}
            childOne={<Header date={date} setDate={setDate}/>}
            childTwo={<Content date={date}/>}
        />
    )
}

