import {Link} from "react-router-dom";
import {useTheme} from "@/components/theme-provider";
import {useAppSelector} from "@/hooks/reduxHooks";
import React, {createContext, useContext, useState} from "react";
import {AlignLeft, LogOut, Moon, MoreVertical, Sun, User, X, ChevronRight} from "lucide-react";
import profile from "../../assets/nurce.png";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import icon1 from "../../assets/sidebar1.svg";
import icon2 from "../../assets/sidebar2.svg";
import icon3 from "../../assets/sidebar3.svg"
import icon4 from "../../assets/sidebar4.svg"
import icon5 from "../../assets/sidebar5.svg"
import icon6 from "../../assets/sidebar6.svg"
import icon7 from "../../assets/sidebar7.svg"
import icon8 from "../../assets/logout.svg"
import icon9 from "../../assets/settings.svg"


const SidebarContext = createContext();


// https://github.com/constgenius/sidebarReact
export default function Sidebar({children}) {
    const user = useAppSelector((state) => state.user.user);
    const {setTheme} = useTheme()

    const [expanded, setExpanded] = useState(true);
    return (
        <>
            <aside className="h-[calc(100vh-71px)] ">
                <nav className="h-full flex flex-col bg-white dark:bg-black  pt-12">
                    <div className="p-4 pb-2 flex justify-between items-center">
                        <button
                            onClick={() => setExpanded((curr) => !curr)}
                            className="p-1.5 rounded-lg  hover:bg-gray-100">
                            <AlignLeft/>
                        </button>
                        {expanded && (
                            <button
                                onClick={() => setExpanded((curr) => !curr)}
                                className="p-1.5 rounded-lg  hover:bg-gray-100">
                                <X/>
                            </button>
                        )}
                    </div>

                    <SidebarContext.Provider value={{expanded}}>
                        <ul className="flex-1  mt-5">{children}</ul>
                    </SidebarContext.Provider>

                    <div className="border-t flex p-3">
                        <img src={profile} className="w-10 h-10 rounded-md"/>
                        <div
                            className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"} `}
                        >
                            <div className="leading-4">
                                <h4 className="font-semibold">
                                    {user?.first_name} {user?.last_name}
                                </h4>
                                <span className="text-xs text-gray-600">{user?.email}</span>
                            </div>

                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div className="flex items-center gap-2 cursor-pointer">
                                        <MoreVertical size={20}/>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                                    <DropdownMenuSeparator/>
                                    <DropdownMenuItem>
                                        <Link to="profile">
                                          <span className={"flex"}>
                                            <User className="mr-2 h-4 w-4"/>
                                            Account Details
                                          </span>
                                        </Link>
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator/>

                                    <DropdownMenuSub>
                                        <DropdownMenuSubTrigger>
                                            <Sun
                                                className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"/>
                                            <Moon
                                                className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"/>
                                            <span>Theme</span>
                                        </DropdownMenuSubTrigger>
                                        <DropdownMenuPortal>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem onClick={() => setTheme("light")}>
                                                    Light
                                                </DropdownMenuItem>
                                                <DropdownMenuItem onClick={() => setTheme("dark")}>
                                                    Dark
                                                </DropdownMenuItem>
                                                <DropdownMenuItem onClick={() => setTheme("system")}>
                                                    System
                                                </DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuPortal>
                                    </DropdownMenuSub>
                                    <DropdownMenuSeparator/>
                                    <DropdownMenuSeparator/>
                                    <a href="/logout">
                                        <DropdownMenuItem>
                                          <span className={"flex"}>
                                            <LogOut className="mr-2 h-4 w-4"/>
                                            Logout
                                          </span>
                                        </DropdownMenuItem>
                                    </a>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    );
}

export function SidebarItem({icon, text, active, alert}) {
    const {expanded} = useContext(SidebarContext);

    switch (icon) {
        case 1:
            icon = icon1;
            break;
        case 2:
            icon = icon2;
            break;
        case 3:
            icon = icon3;
            break;
        case 4:
            icon = icon4;
            break;
        case 5:
            icon = icon5;
            break;
        case 6:
            icon = icon6;
            break;
        case 7:
            icon = icon7;
            break;
        case 8:
            icon = icon8;
            break;
        case 9:
            icon = icon9;
            break;
        default:
            break;

    }
    return (
        <li
            className={`relative flex items-center py-4 px-6  font-medium  cursor-pointer transition-colors group text-gray-600 transition-all ease-in-out duration-500 ${active ? "bg-indigo-50 font-black text-gray-800" : "hover:bg-indigo-50 text-gray-600"}`}
        >
            <img src={icon} className="w-6 h-6"/>
            <span
                className={`overflow-hidden transition-all ${expanded ? "w-44 ml-3" : "w-0"}`}
            >
        {text}
      </span>
            {active && expanded && (
               <div>
                   <ChevronRight className="font-black"/>
               </div>
            )}
            {/*{active && (*/}
            {/*    <div*/}
            {/*        className={`absolute left-0 w-1 h-full bg-indigo-500 ${expanded ? "top-0" : "top-2"}`}*/}
            {/*    ></div>*/}
            {/*)}*/}
            {/*{alert && (*/}
            {/*    <div*/}
            {/*        className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`}*/}
            {/*    ></div>*/}
            {/*)}*/}

            {!expanded && (
                <div
                    className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
                >
                    {text}
                </div>
            )}
        </li>
    );
}
