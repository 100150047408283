import {
  ThumbsUp,
  CircleCheck,
} from "lucide-react";
import HistoryLog from "@/components/historyLog";
import React from "react";

const Icon = ({ icon }) => {
  return <div className=" bg-slate-100 rounded-[40%] p-2 border">{icon}</div>;
};

export default function ActionTab({eoi , isEdit}) {
  const actions = [
    {
      id: 1,
      name: "Thank you mail",
      log: "Email sent on 25th of May 2024 at 04:15 AM",
    },
    {
      id: 2,
      name: "Application form",
      log: "Email sent on 25th of May 2024 at 04:15 AM",
    },
    {
      id: 3,
      name: "Remainder 1",
      log: "Email sent on 25th of May 2024 at 04:15 AM",
    },
  ];

  return (
    <div>
      {/* Actions */}
      <section className="w-full pt-2">
        <div className="mt-4">
          <table className="min-w-full">
            <thead className="border-b">
              <tr className="text-gray-400 font-medium">
                <th className="text-left py-2 ">Actions</th>
                <th className="text-center py-2 px-6">Status</th>
                <th className="text-left py-2">Activity log</th>
              </tr>
            </thead>
            <tbody className="border-b">
              {actions.map((action) => (
                <tr key={action.id} className="py-3 w-full">
                  <td className="text-left py-4 w-[45%]">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-700">
                        {action.name}
                      </span>
                      <div className="flex justify-end items-center gap-4 ">
                        <ThumbsUp className="text-gray-400 h-5 w-5" />
                        <ThumbsUp className="text-gray-400 h-5 w-5" />
                      </div>
                    </div>
                  </td>
                  <td className="text-center flex items-end justify-center py-6 m-auto px-6">
                    <div className="h-full ">
                      <CircleCheck className="text-gray-400 h-4 w-4 " />
                    </div>
                  </td>
                  <td className="text-left py-4 w-[40%] text-sm text-gray-700">
                    {action.log}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      { eoi.id && <HistoryLog modelName={"eoi"} modelID={eoi.id} /> }
     
    </div>
  );
}
