import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpApi } from "../../../api/http.api";
import { notificationController } from "../../../controllers/notificationController";
import dayjs from "dayjs";
import { BookOpenText, Dna, Pencil } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import HistoryLog from "../../../components/historyLog";

export default function VacancyDetails() {
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
  const { ID } = useParams();
  const { pathname } = useLocation();
  const [vacancy, setVacancy] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchVacancy = async () => {
    setLoading(true);
    try {
      const response = await httpApi.get(`/vacancy/${ID}`);
      setVacancy(response.data);
      console.log(response.data);
    } catch (error) {
      notificationController.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchVacancy();
  }, [pathname]);

  const formatDate = (date) => {
    const Thedate = dayjs(date);
    const formattedDate = Thedate.format("DD-MM-YYYY");
    return formattedDate;
  };

  const handleNavigate = () => {
    if (vacancy.is_replacement) {
      navigate(`/vacancy/replacement/${ID}/edit`);
    } else {
      navigate(`/vacancy/${ID}/edit`);
    }
  };

  return (
    <div className="flex flex-col p-4  bg-white rounded-lg border   min-w-full relative" >
      <div className="">
        {loading ? (
          <div className="flex justify-center mt-10 w-full items-center gap-3 py-16 h-full">
            Loading...
            <div className="w-8 h-8 border-5 border-t-2 border-blue-700 rounded-full animate-spin"></div>
          </div>
        ) : (
          <div className="flex gap-5 flex-col ">
            <div
              className="absolute top-3 right-2  cursor-pointer p-3 "
              onClick={handleNavigate}
            >
              <Pencil className={"w-5 h-5 text-gray-700"}/>
            </div>

            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col mt-2 text-xs leading-4 text-slate-600 max-md:mt-10">
                <div className="flex gap-3 text-base items-center">
                  <BookOpenText className="shrink-0 w-5 aspect-[1.06]" />
                  <div className="flex-auto my-auto text-lg">VBC Information</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex flex-col md:flex-row mb-4 text-xs leading-4 text-slate-600">
                <div className="w-1/4 font-medium">Job Title</div>
                <div className="w-2/3 max-w-[350px]">{vacancy?.job_title}</div>
              </div>
              <div className="flex flex-col md:flex-row mb-4 text-xs leading-4 text-slate-600">
                <div className="w-1/4 font-medium">Place</div>
                <div className="w-2/3">{vacancy?.place}</div>
              </div>
              <div className="flex flex-col md:flex-row mb-4 text-xs leading-4 text-slate-600">
                <div className="w-1/4 font-medium">Salary</div>
                <div className="w-2/3">{vacancy?.salary}</div>
              </div>
              <div className="flex flex-col md:flex-row mb-4 text-xs leading-4 text-slate-600">
                <div className="w-1/4 font-medium">TARGET DATE</div>
                <div className="w-2/3">{formatDate(vacancy?.created_at)}</div>
              </div>
            </div>

            <hr className={"mr-10"} />

            <div className="flex flex-col md:flex-row mb-4 text-xs leading-4 text-slate-600">
              <div className="w-1/4 font-medium">KEY METRICS</div>
              <div className="w-2/3 space-y-4 pr-4">
                {/* row 1 */}
                <div className="border-b py-4 pb-6">
                  <div className="flex items-center space-x-2">
                    <Checkbox id="terms" />
                    <label
                      htmlFor="terms"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Accept terms and conditions
                    </label>
                  </div>
                  <div className="grid grid-cols-3 gap-4 items-center justify-start my-4">
                    <Select>
                      <SelectTrigger className="max-w-[180px]  rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select>
                      <SelectTrigger className="max-w-[180px] rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select>
                      <SelectTrigger className="max-w-[180px] rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                 {/*row 2*/}
                <div className="border-b py-4 pb-6">
                  <div className="flex items-center space-x-2">
                    <Checkbox id="terms" />
                    <label
                      htmlFor="terms"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Accept terms and conditions
                    </label>
                  </div>
                  <div className="flex gap-4 items-center justify-start my-4">
                    <Select>
                      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select>
                      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select>
                      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">
                        <SelectValue placeholder="Team assigned" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="apple">Apple</SelectItem>
                          <SelectItem value="banana">Banana</SelectItem>
                          <SelectItem value="blueberry">Blueberry</SelectItem>
                          <SelectItem value="grapes">Grapes</SelectItem>
                          <SelectItem value="pineapple">Pineapple</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                {/*/!* row 3 *!/*/}
                {/*<div className="border-b py-4 pb-6">*/}
                {/*  <div className="flex items-center space-x-2">*/}
                {/*    <Checkbox id="terms" />*/}
                {/*    <label*/}
                {/*      htmlFor="terms"*/}
                {/*      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
                {/*    >*/}
                {/*      Accept terms and conditions*/}
                {/*    </label>*/}
                {/*  </div>*/}
                {/*  <div className="flex gap-4 items-center justify-start my-4">*/}
                {/*    <Select>*/}
                {/*      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">*/}
                {/*        <SelectValue placeholder="Team assigned" />*/}
                {/*      </SelectTrigger>*/}
                {/*      <SelectContent>*/}
                {/*        <SelectGroup>*/}
                {/*          <SelectItem value="apple">Apple</SelectItem>*/}
                {/*          <SelectItem value="banana">Banana</SelectItem>*/}
                {/*          <SelectItem value="blueberry">Blueberry</SelectItem>*/}
                {/*          <SelectItem value="grapes">Grapes</SelectItem>*/}
                {/*          <SelectItem value="pineapple">Pineapple</SelectItem>*/}
                {/*        </SelectGroup>*/}
                {/*      </SelectContent>*/}
                {/*    </Select>*/}
                {/*    <Select>*/}
                {/*      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">*/}
                {/*        <SelectValue placeholder="Team assigned" />*/}
                {/*      </SelectTrigger>*/}
                {/*      <SelectContent>*/}
                {/*        <SelectGroup>*/}
                {/*          <SelectItem value="apple">Apple</SelectItem>*/}
                {/*          <SelectItem value="banana">Banana</SelectItem>*/}
                {/*          <SelectItem value="blueberry">Blueberry</SelectItem>*/}
                {/*          <SelectItem value="grapes">Grapes</SelectItem>*/}
                {/*          <SelectItem value="pineapple">Pineapple</SelectItem>*/}
                {/*        </SelectGroup>*/}
                {/*      </SelectContent>*/}
                {/*    </Select>*/}
                {/*    <Select>*/}
                {/*      <SelectTrigger className="w-[180px] rounded-full !h-8 bg-slate-100 text-sm">*/}
                {/*        <SelectValue placeholder="Team assigned" />*/}
                {/*      </SelectTrigger>*/}
                {/*      <SelectContent>*/}
                {/*        <SelectGroup>*/}
                {/*          <SelectItem value="apple">Apple</SelectItem>*/}
                {/*          <SelectItem value="banana">Banana</SelectItem>*/}
                {/*          <SelectItem value="blueberry">Blueberry</SelectItem>*/}
                {/*          <SelectItem value="grapes">Grapes</SelectItem>*/}
                {/*          <SelectItem value="pineapple">Pineapple</SelectItem>*/}
                {/*        </SelectGroup>*/}
                {/*      </SelectContent>*/}
                {/*    </Select>*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            </div>
            <HistoryLog />

          </div>
        )}
      </div>
    </div>
  );
}
