import React from "react";
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { httpApi } from "../../api/http.api";
import dayjs from "dayjs";
import MainLayout from "../../components/MainLayout";

const Divider = ({ className }) => (
    <div className={`shrink-0 w-px border border-solid ${className}`} />
);

const Icon = ({ src, alt, className }) => (
    <img loading="lazy" src={src} alt={alt} className={className} />
);




function Content() {
    const [vacancyTemplates, setVacancyTemplates] = useState([])
    const [incompleteReports, setIncompleteReports] = useState([])

    function fetchVacancyTemplates() {
        // issue api same endpoint vacancy/templates && vacancy/:id
        httpApi.get('/vacancy/templates/').then(response => {
            setVacancyTemplates(response.data.data)
            console.log(response.data)
        })
        httpApi.get('/vacancy/templates/incomplete').then(response => {
            setIncompleteReports(response.data.data)
            console.log(response.data)
        })
    }

    useEffect(() => {
        fetchVacancyTemplates()
    }, [])
    return (
        <div
            className="flex w-full">
            <div className="w-1/2">
                <div className="p-4">
                    <h2 className="text-lg font-semibold mb-4 text-gray-600">Templates</h2>
                    <hr/>
                </div>
                <div className="space-y-4">
                    {vacancyTemplates.map((template, index) => (
                        <div key={index}
                             className="grid grid-cols-3 p-4  rounded-lg hover:bg-gray-100">
                            <div>
                                <h3 className="font-semibold text-gray-600">{template.job_title}</h3>
                                <p className="text-sm text-gray-500">{template.location}</p>
                            </div>
                            <Button
                                variant="outline"
                                className="rounded-full !bg-gray-50 !px-6 border border-gray-400 font-bold w-fit !text-gray-500"
                            >
                                {/*Last activity - */}
                                {dayjs(template.updated_at).format('DD-MM-YYYY HH:mm')}
                            </Button>
                            <Link to={"/vacancy/new/" + template.id}>
                                <Button
                                    variant="outline"
                                    className="rounded-full bg-gray-200 !px-8 border border-gray-400 text-gray-600 font-bold transition-all ease-in-out hover:bg-blue-600 hover:text-white
                                        hover:border-blue-600 w-fit mx-auto"
                                >
                                    use this template
                                </Button>
                            </Link>

                        </div>
                    ))}
                </div>
            </div>

            <div className="w-1/2">
                <div className="p-4">
                    <h2 className="text-lg font-semibold mb-4 text-gray-600">Incomplete VBC reports</h2>
                    <hr/>
                </div>
                <div className="space-y-4">
                    {incompleteReports?.map((report, index) => (
                        <div key={index}
                             className="grid grid-cols-3   p-4  rounded-lg hover:bg-gray-100 w-full">
                            <div>
                                <h3 className="font-semibold text-gray-600">{report.job_title}</h3>
                                <p className="text-sm text-gray-500">{report.job_title}</p>
                            </div>
                            <Button
                                onClick={() => alert('This will delete the report be careful guyzz')}
                                variant="outline"
                                className="rounded-full bg-gray-50 !px-8 border border-gray-400 text-gray-600 font-bold transition-all ease-in-out hover:bg-red-600 hover:text-white
                                        hover:border-red-600 w-fit mx-auto"
                            >
                                Discard
                            </Button>
                            <Link to={"/vacancy/new/" + report.id}>
                                <Button
                                    variant="outline"
                                    className="rounded-full bg-gray-200 !px-8 border border-gray-400 text-gray-600 font-bold transition-all ease-in-out hover:bg-blue-600 hover:text-white
                                        hover:border-blue-600 w-fit mx-auto"
                                >
                                    Continue
                                </Button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}


export default function VacancyTemplatePage() {


    return (
        <>
            <MainLayout title={"Vacancy Templates"} childTwo={<Content/>}/>
        </>

    )

}