import {useEffect, useState} from "react";
import {CalendarDays, Check, Loader, X,} from "lucide-react";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import {ChartContainer, ChartTooltipContent,} from "@/components/ui/chart";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select";
import {httpApi} from "../../api/http.api";
import CountCard from "../../components/CountCard";

// const monthlyData = [
//     {month: "January", vacancy: 186,},
//     {month: "February", vacancy: 305,},
//     {month: "March", vacancy: 237,},
//     {month: "April", vacancy: 73},
//     {month: "May", vacancy: 209},
//     {month: "June", vacancy: 214,},
//     {month: "July", vacancy: 300,},
//     {month: "August", vacancy: 250,},
//     {month: "September", vacancy: 200,},
//     {month: "October", vacancy: 150,},
//     {month: "November", vacancy: 100,},
//     {month: "December", vacancy: 50,},
// ];
//
// const yearlyData = [
//     {year: 2014, vacancy: 300,},
//     {year: 2015, vacancy: 500,},
//     {year: 2017, vacancy: 800,},
//     {year: 2018, vacancy: 1000,},
//     {year: 2019, vacancy: 1200,},
//     {year: 2020, vacancy: 1500,},
//     {year: 2021, vacancy: 1800,},
//     {year: 2022, vacancy: 1700,},
//     {year: 2023, vacancy: 2000},
//     {year: 2024, vacancy: 1900,},
// ];

const chartConfig = {
    vacancy: {
        label: "Vacancy",
        color: "#87CEEB",
    },
};


const items = [
    {id: 0, name: "Active Expression", icon: <Loader/>, count: '12'},
    {id: 1, name: " Success Applications", icon: <Check/>, count: '06'},
    {id: 2, name: "Failed Expression", icon: <X/>, count: '04'},
    {id: 3, name: "Active Months", icon: <CalendarDays/>, count: '08'},
]

export default function VacancyChart() {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("monthly");
    const [chartData, setChartData] = useState([]);

    const fetchChartData = () => {
        httpApi.get('/chart/vacancy/').then(response => {
            console.log(response.data)
            setData(response.data)
            setChartData(filter === "monthly" ? response.data.monthlyData : response.data.yearlyData)
        }, error => {
            console.log(error)
        })

    }
    useEffect(() => {
        fetchChartData()
    }, [])

    const handleFilterChange = (value) => {
        setFilter(value);
        setChartData(value === "monthly" ? data?.monthlyData : data?.yearlyData);
    }
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="bg-white border rounded-xl p-4 dark:bg-slate-800 min-w-[500px]">
            <div className="flex justify-between pb-2">
                {/*<h1 className="text-xl">Performance Chart</h1>*/}
                <div className="flex gap-2 items-center justify-center">
                    <div className="flex items-center justify-center gap-2">
                        <div className="h-3 w-3 bg-gray-200"></div>
                        Selected
                    </div>
                    <div className="flex items-center justify-center gap-2">
                        <div className="h-3 w-3 bg-blue-200"></div>
                        Applied
                    </div>
                </div>
                <Select onValueChange={handleFilterChange}>
                    <SelectTrigger
                        className="w-[100px] font-bold rounded-full !h-8 bg-gray-50 text-sm border border-gray-400 text-gray-500 !outline-none !ring-0"
                    >
                        <SelectValue placeholder={capitalize(filter)}/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value="monthly">Monthly</SelectItem>
                            <SelectItem value="yearly">Yearly</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <ChartContainer config={chartConfig}>
                <BarChart width={600} height={300} data={chartData}>
                    <CartesianGrid vertical={false}/>
                    <XAxis
                        dataKey={filter === "monthly" ? "month" : "year"}
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) => (filter === "monthly" ? value.slice(0, 3) : value)}
                    />
                    <YAxis/>
                    <Tooltip cursor={false} content={<ChartTooltipContent indicator="dashed"/>}/>
                    <Bar dataKey="vacancy" fill={chartConfig.vacancy.color} radius={8}/>
                </BarChart>
            </ChartContainer>
            <div className="border-t ">
                <div className="grid gap-y-4 gap-x-2 mt-6" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))" }}>
                    {items.map((item, index) => (
                        <CountCard count={item.count} icon={item.icon} index={index} key={index} name={item.name}/>
                    ))}
                </div>
            </div>
        </div>
    );
}
