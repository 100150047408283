import React from 'react';
import { UserRound } from 'lucide-react';

function ProgressBar({ name, progress }) {
    return (
        <div className="flex gap-2">
            <div className="border w-fit p-4 rounded-full flex items-center justify-center">
                <UserRound className="text-bold "/>
            </div>
            <div className="flex flex-col items-start justify-center w-[160px]">
                <div className="progress-bar-name">{name}</div>
                <div className="w-full bg-gray-200 rounded-full border border-black/40 p-[1px]">
                    <div
                        className="bg-blue-400/30 h-2 rounded-full "
                        style={{width: `${progress}%`}}
                    />
                </div>

            </div>
        </div>
    );
}

export default ProgressBar;