import React, {useState} from 'react';
import { Button } from "@/components/ui/button"
import EoiList from "./EoiList";
import { Link , Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Plus, CalendarDays, Calendar as CalendarIcon, SlidersHorizontal,} from "lucide-react";
import { Calendar } from "../../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover"
import {addDays, format} from "date-fns";
import { cn } from "@/lib/utils"
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/components/ui/resizable"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Checkbox } from "@/components/ui/checkbox"
import MainLayout from "../../components/MainLayout";
import { useSettings } from "../../context/SettingsContext"

const Divider = ({ className }) => (
    <div
        className={`shrink-0 w-px border border-solid bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[20px] ${className}`} />
);
const Icon = ({ src, alt, className }) => (
    <img loading="lazy" src={src} alt={alt} className={className} />
);

const Header = ({ date, setDate }) => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showStatusBar, setShowStatusBar] = useState(false)
    const [classNamespl, setClassNamespl] = useState("")


    return (
        <div className="flex gap-3 px-5">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div variant="outline" className="">
                        <SlidersHorizontal
                            className="shrink-0 self-start aspect-[1.12] w-[18px] cursor-pointer  cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                    <DropdownMenuLabel className="flex gap-2 items-center">
                        <SlidersHorizontal className="w-[16px]"/>
                        <span className="font-bold">VBC Filter</span>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator/>
                    <div className=" p-2">
                        <h3 className="text-blue-500 font-semibold">
                            Status Filter
                        </h3>
                        <div className="p-2 space-y-2">
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    New VBC
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Progressing
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className=" p-2">
                        <h3 className="text-blue-500 font-semibold">
                            Job Type Filter
                        </h3>
                        <div className="p-2 space-y-2">
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    New VBC
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Progressing
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 p-2">
                        <h3 className="text-blue-500 font-semibold">
                            Location Filter
                        </h3>
                        <div className="p-2 space-y-2">
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    New VBC
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox id="terms" checked={showStatusBar}
                                          onCheckedChange={setShowStatusBar}/>
                                <label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Progressing
                                </label>
                            </div>
                        </div>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>

            <Divider/>
            <Icon
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/258654b4dc9cc29c4f5baf02032b98698963384b4522aff366021aadd20a99db?apiKey=ae76636b06d44b88a66a58519c620a05&"
                alt="Sort icon" className="shrink-0 aspect-[1.06] w-[18px] cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
            <Divider/>
            <div className={cn("grid gap-2", classNamespl)}>
                <Popover>
                    <PopoverTrigger asChild>
                        <CalendarDays className="w-[16px] cursor-pointer text-gray-500 hover:text-black active:text-black focus:text-black"/>
                        {/*<CalendarIcon className="mr-2 h-4 w-4"/>*/}

                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 m-2" align="start">
                        <div className="flex items-center jusitfy-center mt-2">
                            <Button
                                id="date"
                                disabled={true}
                                variant={"outline"}
                                className={cn(
                                    "w-[300px] justify-start text-left font-normal mx-auto",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4"/>
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </div>
                        <Calendar
                            initialFocus
                            mode="range"
                            defaultMonth={date?.from}
                            selected={date}
                            onSelect={setDate}
                            numberOfMonths={2}
                        />
                        {/*<div className="w-full flex items-center justify-center">*/}
                        {/*    <Button onClick={() => FilterDateRange()} className="w-[60%] m-4 mx-auto">Apply</Button>*/}
                        {/*</div>*/}
                    </PopoverContent>
                </Popover>
            </div>
            <Divider/>
            <Plus
                onClick={() => navigate('/eoi/new')}
                className={`shrink-0 w-5 cursor-pointer hover:text-black ${pathname === "/eoi/new" || pathname === "/eoi/:ID" ? "text-black" : "text-gray-500"}`}/>

        </div>
    )
}

function Content({date}) {
        const {settings} = useSettings();
        console.log(settings.eoi);
        const navigate = useNavigate();
        const {search} = useLocation()
        const [status,setStatus] = useState('')
    const handleStatusFilter = (status) => {
            if (search.includes(status)) {
                navigate(`/eoi`)
                setStatus('')
            }else {
                navigate(`/eoi?status=${status}`)
                setStatus(status)
            }
    }
    const clsActive = search.includes('status=active');
    const clsTerminated = search.includes('status=terminated');

    return (
        <>
            <div className='py-4 ml-6 space-x-4'>
                <Button variant="outline" onClick={() => handleStatusFilter('active')}
                        className={`rounded-full !px-8 border border-gray-400 !py-1 !h-8 active:bg-gray-200 hover:bg-gray-200 ${clsActive ? 'bg-gray-200' : ''}`}>Active</Button>
                <Button variant="outline" onClick={() => handleStatusFilter('terminated')}
                        className={`rounded-full !px-8 border border-gray-400 !py-1 !h-8 active:bg-gray-200 hover:bg-gray-200 ${clsTerminated ? 'bg-gray-200' : ''}`}>Terminated</Button>
            </div>
            <div className="mt-1 max-md:max-w-full">
                {/* <div className="flex gap-5 max-md:flex-col max-md:gap-0"> */}
                <ResizablePanelGroup direction="horizontal" className="grid grid-cols-2 gap-4 ">
                    <ResizablePanel defaultSize={settings.eoi.section1Width}>
                        <EoiList filterDate={date} status={status}/>
                    </ResizablePanel>
                    <ResizableHandle withHandle/>
                    <ResizablePanel defaultSize={settings.eoi.section2Width}>
                        <section className="max-h-[calc(100vh-265px)] overflow-auto overflow-x-hidden">
                            <Outlet />
                        </section>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </div>
            <div
                className="flex gap-5 mt-8 text-xs leading-4 text-slate-600 max-md:flex-wrap max-md:max-w-full px-2">
                <div className="flex-auto font-medium">
                    <span className="font-extrabold">12</span>
                    <span className=""> Active, </span>
                    <span className="font-extrabold">04</span>
                    <span className=""> Success, </span>
                    <span className="font-extrabold">08</span>
                    <span className=""> Failed in last </span>
                    <span className="font-extrabold">3</span>
                    <span className=""> months</span>
                </div>
                <div className="flex-auto text-right">Latest VBC 08-06-24, 14:33</div>
            </div>
        </>

    )
    ;
}

export default function ExpressionOfInterest() {
    const [date, setDate] = useState({
        from: new Date(2024, 6, 20),
        to: addDays(new Date(2024, 6, 20), 20),
    })

    return (
        <MainLayout
            title={"Expression Of Interest"}
            childOne={<Header date={date} setDate={setDate}/>}
            childTwo={<Content date={date}/>}
        />
    )
}