import React, {useEffect, useState} from 'react';
import {httpApi} from "../../api/http.api";
import {notificationController} from "../../controllers/notificationController";
import dayjs from 'dayjs';
import {useLocation, useNavigate} from 'react-router-dom';
import {debounce} from "lodash-es";
import {ChevronDown, ChevronsDown, ChevronsUp, ChevronUp, Loader} from "lucide-react";
import {Progress} from "@material-tailwind/react";


const Icon = ({src, alt, className}) => (
    <img loading="lazy" src={src} alt={alt} className={className}/>
);

export default function ApplicantList({filterDate}) {
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const navigate = useNavigate();
    const {pathname} = useLocation()

    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        fetchCandidates(page);
    }, [page]);

    const fetchCandidates = debounce((page) => {
        if (hasMore) {
            setLoading(false)
            return;
        }
        setLoading(true)
        httpApi.get(`/eoi?page=${page}`).then(response => {
            setCandidates((prevData) => [...prevData, ...response.data.data]);
            if (response.data.data.length === 0 || response.data.data.length < 10) {
                setHasMore(true)
            }
        }).catch(error => {
            notificationController.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }, 100);

    const [selectedItem, setSelectedItem] = useState(null);
    const today = new Date();

    const handleDays = (date) => {
        const givenDate = new Date(date);
        const diffTime = givenDate - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const formatDate = (date) => {
        const Thedate = dayjs(date);
        const formattedDate = Thedate.format('D/M/YY');
        return formattedDate;
    }

    const handleClick = (item) => {
        setSelectedItem(item);
        console.log(item);
        // navigate('/eoi/' + item?.id);
    }
    const handleLoadMore = () => {
        const tableElement = document.getElementById("tableId");

        if (tableElement) {
            const {scrollTop, scrollHeight, clientHeight} = tableElement;

            console.log("Height:", scrollHeight);
            console.log("ScrollTop:", scrollTop);
            console.log("ClientHeight:", clientHeight);

            // Check if scrolled to the bottom
            if (scrollTop + clientHeight + 1 >= scrollHeight) {
                if (hasMore) return
                setLoading(true)
                // for see loading
                setTimeout(() => {
                    setPage((prevPage) => prevPage + 1);
                }, 500);
            }
        }
    };

    useEffect(() => {
        const tableElement = document.getElementById("tableId");

        if (tableElement) {
            tableElement.addEventListener("scroll", handleLoadMore);
        }

        return () => {
            if (tableElement) {
                tableElement.removeEventListener("scroll", handleLoadMore);
            }
        };
    }, []);


    useEffect(() => {
        const FilterDateRange = () => {
            const filtered = candidates.filter((vacancy) => {
                const Thedate = new Date(vacancy.CreatedAt);
                // console.log(Thedate, 'Thedate')
                // console.log(filterDate.from, 'from')
                // console.log(filterDate.to, 'to')
                return Thedate >= filterDate.from && Thedate <= filterDate.to;
            });
            // console.log(filtered)
            setFilteredCandidates(filtered);
            // setClassNamespl("animate-bounce");
        };

        FilterDateRange();
    }, [filterDate, candidates]);

    useEffect(() => {
        if (selectedItem && pathname !== `/eoi/${selectedItem.id}`) {
            setSelectedItem(null);
        }
    }, [pathname]);

    return (

        <div className="flex flex-col  max-md:ml-0 max-md:w-full ">
            <div
                className="flex flex-col items-start mt-1 max-md:mt-10 max-md:max-w-full max-h-[calc(100vh-265px)] overflow-auto overflow-x-hidden "
                id="tableId">
                <table className="w-full ml-5 p-4">
                    <thead className="text-xs sticky top-0 z-20 bg-gray-50">
                    <tr className="cursor-default select-none">
                        <th className="px-2 py-4 text-left ">Name | Location | Designation</th>
                        <th className="px-2 py-4 text-center">Priority</th>
                        <th className="px-2 py-4 text-center">Assign to</th>
                        <th className="px-2 py-4 text-center">Pathway</th>
                        <th className="px-2 py-4 text-center">Progress</th>
                    </tr>
                    </thead>
                    <tbody className='overflow-auto'>
                    {filteredCandidates.map((candidate, index) => (
                        <tr key={index}
                            className={`cursor-pointer ${selectedItem?.id === candidate.id ? 'bg-white rounded-full shadow-sm text-slate-600' : ''}`}
                            onClick={() => handleClick(candidate)}>
                            <td className="px-2 py-4 ">
                                <div
                                    className="text-base leading-6 text-slate-600 capitalize">{candidate?.first_name} {candidate?.middle_name} {candidate?.last_name}</div>
                                <div className="mt-2 text-xs">{candidate?.country} | {candidate?.designation}</div>
                            </td>
                            <td className="px-2 py-4 text-center">
                                <div className='flex items-center justify-center'>
                                    <ChevronsUp className="text-gray-500"/>
                                    <ChevronsDown className="text-gray-500"/>
                                    <ChevronUp className="text-gray-500"/>
                                    <ChevronDown className="text-gray-500"/>
                                </div>
                            </td>
                            <td className="px-2 py-4 text-center capitalize">{candidate.assign_to}</td>
                            <td className="px-2 py-4 text-center">{formatDate(candidate.CreatedAt)}</td>
                            <td className="px-2 py-4 text-center">
                                <Progress
                                    color="cyan"
                                    value={50}
                                    size="lg"
                                    className="border border-gray-900/10 bg-gray-900/5 p-0.5"
                                />
                            </td>
                            <td className="pr-4 py-4 text-center">
                                <div className="flex items-center justify-center pl-2 pr-4">
                                    {selectedItem?.id === candidate.id && (
                                        <Icon
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e42206b9408b9430811362523a56db063598d8d2c414ebfebe65af02830f66f8?apiKey=ae76636b06d44b88a66a58519c620a05&"
                                            alt="Status icon"
                                            className="shrink-0 my-auto aspect-square w-[18px]"
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    {loading && (
                        <tr className="flex items-center justify-end pr-6">
                            <td colSpan={4} className=" py-4 ">
                                <div className="mx-auto w-fit ">
                                    <Loader className={"animate-spin mx-auto "}/>
                                </div>
                            </td>
                        </tr>
                    )}
                    {hasMore && !loading && (
                        <tr>
                            <td colSpan={4} className="text-center py-4  ">
                                <span className="text-gray-400 mx-auto">No more data</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
