import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar"
import {Label} from "@/components/ui/label"
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import {Input} from "@/components/ui/input"
import MainLayout from "../components/MainLayout";

export default function MyProfilePage() {
    return (
        <MainLayout title={"My Profile"} childTwo={

            <div className="p-6">
                <div className="flex justify-between">
                    <div className="mt-8">
                        <div className="flex gap-4">
                            <div className="flex-1 space-y-6">
                                <Avatar>
                                    <AvatarImage alt="MyProfilePage picture" src="/placeholder.svg?height=40&width=40"/>
                                    <AvatarFallback>U</AvatarFallback>
                                </Avatar>
                                <p className="text-xs text-center mt-2">JPG, PNG Max size of 12mb</p>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <Label htmlFor="title">Title</Label>
                                        <Select>
                                            <SelectTrigger id="title">
                                                <SelectValue placeholder="Select preferred title"/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="mr">Mr.</SelectItem>
                                                <SelectItem value="ms">Ms.</SelectItem>
                                                <SelectItem value="mrs">Mrs.</SelectItem>
                                                <SelectItem value="dr">Dr.</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div className="space-y-2">
                                        <Label htmlFor="first-name">First name*</Label>
                                        <Input id="first-name" placeholder="e.g. Annabelle"/>
                                    </div>
                                    <div className="space-y-2">
                                        <Label htmlFor="middle-name">Middle name</Label>
                                        <Input id="middle-name" placeholder="e.g. Louise"/>
                                    </div>
                                    <div className="space-y-2">
                                        <Label htmlFor="last-name">Last name*</Label>
                                        <Input id="last-name" placeholder="e.g. Smith"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        }/>
    )
}
