import React, {useEffect, useState} from "react";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import {z} from "zod";
import {cn} from "@/lib/utils";
import {Button} from "@/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/ui/popover";
import {format} from "date-fns";
import {CalendarIcon, CircleX, UserRound} from "lucide-react";
import {useToast} from "@/components/ui/use-toast";
import {Calendar} from "@/components/ui/calendar";
import {Switch} from "@/components/ui/switch";
import {Dialog, DialogContent, DialogHeader, DialogTitle,} from "@/components/ui/dialog";
import {ScrollArea} from "../../../components/ui/scroll-area";
import {Input as MInput, Option, Select as MSelect} from "@material-tailwind/react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {httpApi} from "../../../api/http.api";
import {notificationController} from "../../../controllers/notificationController";

const formSchema = z.object({
    job_title: z.string().nonempty("Job title is required"),
    department: z.string().nonempty("Department is required"),
    place: z.string().nonempty("Place is required"),
    salary: z.string().nonempty("Salary is required"),
    hours_per_week: z.string().nonempty("Hours per week is required"),
    start_date: z.date("Start date is required"),
    reason: z.string().nonempty("Reason is required"),
    key_responsibilities: z.string().nonempty("Key responsibilities are required"),
    weekly_cost: z.string().nonempty("Weekly cost is required"),
    budget_available: z.boolean().refine(val => val === true || val === false, {
        message: "Budget availability is required"
    }),
    annual_cost: z.string().nonempty("Annual cost is required"),
    funding_explanation: z.string().optional(),
    benefits: z.string().nonempty("Benefits are required"),
    risks: z.string().nonempty("Risks are required"),
    alternative_options: z.string().nonempty("Alternative options are required"),
    resources: z.string().nonempty("Resources are required"),
    training: z.string().nonempty("Training is required"),
    integration: z.string().nonempty("Integration is required"),
    additional_notes: z.string().optional(),
    save_template: z.boolean().optional(),
    template_name: z.string().optional()
});

export default function NewVacancy() {
    const {toast} = useToast();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [vacancy, setVacancy] = useState(null);
    const {ID} = useParams();
    const {pathname} = useLocation();
    const [isEdit, setIsEdit] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (ID) {
            setLoading(true);
            setIsEdit(true);
            fetchVacancy();
        }
    }, [ID]);

    const fetchVacancy = async () => {
        try {
            const response = await httpApi.get(`/vacancy/${ID}`);
            setVacancy(response.data);
            setIsEdit(true);

            // set the form values
            console.log(response.data);
            form.setValue("job_title", response.data?.job_title);
            form.setValue("department", response.data.department);
            form.setValue("place", response.data.place);
            form.setValue("salary", response.data?.salary);
            form.setValue("hours_per_week", response.data?.hours_per_week);
            form.setValue("start_date", response.data?.start_date);
            form.setValue("reason", response.data?.reason);
            form.setValue("key_responsibilities", response.data?.key_responsibilities);
            form.setValue("weekly_cost", response.data?.weekly_cost);
            form.setValue("budget_available", response.data?.budget_available);
            form.setValue("annual_cost", response.data?.annual_cost);
            form.setValue("funding_explanation", response.data?.funding_explanation);
            form.setValue("benefits", response.data?.benefits);
            form.setValue("risks", response.data?.risks);
            form.setValue("alternative_options", response.data?.alternative_options);
            form.setValue("resources", response.data?.resources);
            form.setValue("training", response.data?.training);
            form.setValue("integration", response.data?.integration);
            form.setValue("additional_notes", response.data?.additional_notes);
            form.setValue("save_template", response.data?.save_template);
            setIsTemplate(response.data?.save_template);
            form.setValue("template_name", response.data?.template_name);

        } catch (error) {
            notificationController.error(error);
        } finally {
            setLoading(false);
        }
    };

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            job_title: "",
            department: "",
            place: "",
            salary: "",
            hours_per_week: "",
            start_date: "",
            reason: "",
            key_responsibilities: "",
            weekly_cost: "",
            budget_available: false,
            annual_cost: "",
            funding_explanation: "",
            benefits: "",
            risks: "",
            alternative_options: "",
            resources: "",
            training: "",
            integration: "",
            additional_notes: "",
            save_template: false,
            template_name: ""
        }
    });

    async function onSubmit(values) {
        try {
            console.log(values);
            const data = {
                ...values,
                is_replacement: false,
                is_template: values.save_template,
                template_name: values.save_template ? values.job_title : ""
            };

            let response;
            if (isEdit) {
                response = await httpApi.patch(`/vacancy/${ID}`, data);
            } else {
                response = await httpApi.post("/vacancy/create", data);


            }

            if (response && response.data) {
                setDialogOpen(true);
                console.log(response.data); // Log the entire response data to inspect its structure
                const id = response.data.id; // Ensure this matches the actual structure of your response
                if (id) {
                    setTimeout(() => {
                        setDialogOpen(false);
                        navigate(`/vacancy/${id}`);

                    }, 3000);
                    toast(isEdit ? "Vacancy Updated Successfully" : "Vacancy Created Successfully");
                } else {
                    console.error("ID not found in response data");
                }
            } else {
                console.error("No response data");
            }
        } catch (error) {
            console.error(error);
        }
    }

    const options1 = [
        {value: "optionA", label: "Option A"},
        {value: "optionB", label: "Option B"},
        {value: "optionC", label: "Option C"},
        {value: "optionD", label: "Option D"},
    ];
    const onerror = (error) => {
        console.log(error);
    }

    const handleNavigate = () => {
        navigate(`/vacancy/${ID}`);
    }

    return (
        <>
            <div className="border rounded-xl bg-white dark:bg-slate-800 p-4 ">
                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <div className="loader">Loading...</div>
                    </div>
                ) : (
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit, onerror)} className="space-y-8 p-2">
                            <ScrollArea>
                                {/* position detials */}
                                <section className="space-y-6">
                                    <div className="flex gap-5 justify-between">
                                        <div className="flex items-center justify-start gap-3 font-semibold">
                                            <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                            Position Details
                                        </div>
                                        {isEdit && (
                                            <div
                                                className="absolute top-0 right-2  cursor-pointer  "
                                                onClick={handleNavigate}
                                            >
                                                <CircleX className={"w-5 h-5 text-gray-700"}/>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormField
                                            control={form.control}
                                            name="job_title"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        {/*<Input*/}
                                                        {/*  placeholder="Proposed Job Title"*/}
                                                        {/*  custom={true}*/}
                                                        {/* */}
                                                        {/*/>*/}

                                                        <MInput {...field} variant="standard"
                                                                label="Proposed Job Title"
                                                                placeholder="Proposed Job Title"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="department"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MSelect {...field} variant="standard"
                                                                 label="Select Version">
                                                            {options1.map((option) => (
                                                                <Option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </MSelect>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="place"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MSelect {...field} variant="standard" label="Place/Region">
                                                            {options1.map((option) => (
                                                                <Option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </MSelect>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="salary"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Proposed Salary/Rate of Pay"
                                                                placeholder="Proposed Salary/Rate of Pay"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="hours_per_week"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Proposed Hours / Week"
                                                                placeholder="Proposed Hours / Week"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="start_date"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Popover>
                                                            <PopoverTrigger asChild>
                                                                <Button
                                                                    variant={"outline"}
                                                                    className={cn(
                                                                        "w-full pl-3 text-left font-normal border-0 border-b-2 shadow-none rounded-none border-gray-400 place-self-end mt-2",
                                                                        !field.value && "text-muted-foreground",
                                                                    )}
                                                                >
                                                                    {field.value ? (
                                                                        format(field.value, "PPP")
                                                                    ) : (
                                                                        <span>Proposed Start Date</span>
                                                                    )}
                                                                    <CalendarIcon
                                                                        className="ml-auto h-4 w-4 opacity-50"/>
                                                                </Button>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                className="w-auto p-0"
                                                                align="start"
                                                            >
                                                                <Calendar
                                                                    mode="single"
                                                                    selected={field.value}
                                                                    onSelect={field.onChange}
                                                                    disabled={(date) =>
                                                                        date > new Date() ||
                                                                        date < new Date("1900-01-01")
                                                                    }
                                                                    initialFocus
                                                                />
                                                            </PopoverContent>
                                                        </Popover>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </section>

                                {/* Business Case */}
                                <section className="space-y-6 mt-8">
                                    <div className="flex items-center justify-start gap-3 font-semibold">
                                        <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                        Business Case
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormField
                                            control={form.control}
                                            name="reason"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Justification for Replacement"
                                                                placeholder="Reason for new appointment"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="reason"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Consequences of not replacing"
                                                                placeholder="How does this role align with business objectives"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="key_responsibilities"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-gray-600">
                                                        Additional Notes
                                                    </FormLabel>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Key responsibilities of the new role"
                                                                placeholder="Key responsibilities of the new role"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </section>

                                {/* Financial Impact */}
                                <section className="space-y-6 mt-6">
                                    <div className="flex items-center justify-start gap-3 font-semibold">
                                        <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                        Financial Impact
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormField
                                            control={form.control}
                                            name="weekly_cost"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Weekly cost of new appointment"
                                                                placeholder="Weekly cost of new appointment"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="annual_cost"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        {/*readOnly*/}
                                                        <MInput {...field} variant="standard"
                                                                label="Annual cost of new appointment"
                                                                placeholder="Annual cost of new appointment (Auto-calculated, Read-only)"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        {form.getValues().budget_available}{" "}
                                        <FormField
                                            control={form.control}
                                            name="budget_available"
                                            render={({field}) => (
                                                <FormItem>
                                                    <div className="flex items-center justify-start gap-4">
                                                        <FormLabel className="text-gray-600">
                                                            Budget Available
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Switch
                                                                checked={field.value === true}
                                                                onCheckedChange={(checked) =>
                                                                    field.onChange(checked)
                                                                }
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <div className="text-gray-600 text-sm">Yes/No</div>
                                                    </div>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        {!form.getValues("budget_available") && (
                                            <FormField
                                                control={form.control}
                                                name="funding_explanation"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <MInput {...field} variant="standard"
                                                                    label="Funding explanation"
                                                                    placeholder="if No, explain how it will be funded"/>
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        )}
                                    </div>
                                </section>

                                {/* Benefits & Risks */}
                                <section className="space-y-6 mt-8">
                                    <div className="flex items-center justify-start gap-3 font-semibold">
                                        <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                        Benefits & Risks
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormField
                                            control={form.control}
                                            name="benefits"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Benefits of creating this new position"
                                                                placeholder="Benefits of creating this new position"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="risks"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Risks of not creating this position"
                                                                placeholder="Risks of not creating this position"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="alternative_options"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Alternative options considered"
                                                                placeholder="Alternative options considered"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </section>

                                {/* Operational Impact */}
                                <section className="space-y-6 mt-8">
                                    <div className="flex items-center justify-start gap-3 font-semibold">
                                        <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                        Operational Impact
                                    </div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormField
                                            control={form.control}
                                            name="resources"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard" label="Resources"
                                                                placeholder="Required Resources for new position"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="training"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Training requirements"
                                                                placeholder="Training requirements"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="integration"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <MInput {...field} variant="standard"
                                                                label="Integration with existing team structure"
                                                                placeholder="Integration with existing team structure"/>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </section>

                                {/* Additional Notes */}
                                <section className="space-y-6 mt-8 grid grid-cols-2 gap-6">
                                    <FormField
                                        control={form.control}
                                        name="additional_notes"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <MInput {...field} variant="standard" label="Additional notes"
                                                            placeholder="Enter Text"/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </section>
                                {isEdit && !isTemplate ? (
                                    <section className="space-y-6 mt-6 flex items-center justify-end ">
                                        <Button
                                            type="submit"
                                            onClick={() => setDialogOpen(true)}
                                            className="px-6"
                                        >
                                            Update
                                        </Button>
                                    </section>
                                ) : (!isEdit && (
                                        <section className="justify-between space-y-6 mt-6">
                                            <div className="flex items-center justify-start gap-4">
                                                <div className="text-sm text-gray-500">
                                                    Do you want to save as Template if Yes,promt for
                                                    Template Name
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name="save_template"
                                                    render={({field}) => (
                                                        <FormItem>
                                                            <div
                                                                className="flex items-center justify-center gap-1 flex-col">
                                                                <FormLabel className="text-gray-600">
                                                                    Yes/No
                                                                </FormLabel>
                                                                <FormControl>
                                                                    <Switch
                                                                        checked={field.value === true}
                                                                        onCheckedChange={(checked) => {
                                                                            field.onChange(checked)
                                                                            console.log(checked)
                                                                        }
                                                                        }
                                                                        {...field} />
                                                                </FormControl>
                                                            </div>

                                                            <FormMessage/>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex gap-4 justify-end ">
                                                <div className="flex flex-col items-center justify-center">
                                                    <Button type="submit" className="">
                                                        Save Progress
                                                    </Button>
                                                    {/*<span className="text-gray-500 text-sm">Last Saved at 2:55pm</span>*/}
                                                </div>
                                                <div className="flex flex-col items-center justify-center">
                                                    <Button
                                                        type="submit"
                                                        className=""
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </section>
                                    )

                                )}
                            </ScrollArea>
                            {isTemplate && (<section className="justify-between space-y-6 mt-6">
                                    <div className="flex items-center justify-start gap-4">
                                        <div className="text-sm text-gray-500">
                                            Do you want to save progress as Template,
                                        </div>
                                        <FormField
                                            control={form.control}
                                            name="save_template"
                                            render={({field}) => (
                                                <FormItem>
                                                    <div className="flex items-center justify-center gap-1 flex-col">
                                                        <FormLabel className="text-gray-600">
                                                            Yes/No
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Switch
                                                                checked={field.value === true}
                                                                onCheckedChange={(checked) => {
                                                                    field.onChange(checked)
                                                                    console.log(checked)
                                                                }
                                                                }
                                                                {...field} />
                                                        </FormControl>
                                                    </div>

                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div className="flex gap-4 justify-end ">
                                        <div className="flex flex-col items-center justify-center">
                                            <Button type="submit" className="">
                                                Save Progress
                                            </Button>
                                            {/*<span className="text-gray-500 text-sm">Last Saved at 2:55pm</span>*/}
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <Button
                                                type="submit"
                                                className=""
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </form>
                    </Form>
                )}
                <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                    <DialogContent
                        className="sm:max-w-[625px] py-24 px-16 border flex items-center flex-col justify-center">
                        <DialogHeader className="pb-6">
                            <DialogTitle className="text-3xl">
                                VBC {isEdit ? "Updated" : "Created"} Successfully
                            </DialogTitle>
                        </DialogHeader>
                        <div className="flex flex-col gap-6 p-4 px-8 text-gray-500">
                            <p>VB Case ID 40222</p>
                            <p>Date {isEdit ? "Update" : "Create"} 25th May 2024</p>
                            <p>{isEdit ? "Updated" : "Created"} By John Doe</p>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
}
