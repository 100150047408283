import React from 'react';
import {LoaderCircle} from "lucide-react";
import dayjs from "dayjs";

const applicants = [
    {
        id: 1,
        name: "Anju Mathew",
        applicantId: "6453",
        applicationDate: "03-06-24",
        lastActivity: "03-06-2024 21:46",
        progressPercentage: 60
    },
];

export default function VacancyShortList() {
    return (
        <div className="overflow-x-auto  bg-white rounded-lg border py-10 px-5  min-w-full">
            <table className="min-w-full text-xs leading-4">
                <thead className="bg-gray-50">
                <tr>
                    <th className="px-2 py-3 text-left">No.</th>
                    <th className="px-2 py-3 text-left">Name</th>
                    <th className="px-2 py-3 text-left">Applicant ID</th>
                    <th className="px-2 py-3 text-center">Application date</th>
                    <th className="px-2 py-3 text-center">Progress</th>
                    <th className="px-2 py-3 text-center">Last log</th>
                    <th className="px-2 py-3 text-center">Delete</th>
                </tr>
                </thead>
                <tbody>
                {applicants.map((applicant, index) => (
                    <tr key={index} className="border-b">
                        <td className="px-2 py-2">{applicant.id}</td>
                        <td className="px-2 py-2">{applicant.name}</td>
                        <td className="px-2 py-2">{applicant.applicantId}</td>
                        <td className="px-2 py-2 text-center">{applicant.applicationDate}</td>
                        <td className="px-2 py-2 text-center">
                            <LoaderCircle className="shrink-0 w-[20px] mx-auto"/>
                        </td>
                        <td className="px-2 py-2 text-center">
                            {dayjs(applicant.lastActivity).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-2 py-2 text-center">
                            <button aria-label="Delete applicant">
                                <img loading="lazy"
                                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/0138cf82eee8894bb8994c35ec1e55e28b7e8dabaa804abb85f75dffcddfe25d?apiKey=ae76636b06d44b88a66a58519c620a05&"
                                     className="shrink-0 self-stretch mx-auto aspect-[0.81] w-[13px]" alt=""/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}