import React, {useEffect} from "react";
import {Button} from "../../../components/ui/button";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm} from "react-hook-form";
import {z} from "zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form";
import HistoryLog from "@/components/historyLog";
import {httpApi} from "../../../api/http.api";
import { Select as MSelect, Option } from "@material-tailwind/react";

const formSchema = z.object({
    vbc_no: z.string().nonempty("VBC No. is required"),
    staff_in_charge: z.string().nonempty("Staff in charge is required"),
    source: z.string().nonempty("Source is required"),
});

export default function DetailsTab({eoi , isEdit}) {

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            vbc_no: "",
            staff_in_charge: "",
            source: "",
        },
    });


    function onSubmit(data) {
        console.log(data);
    }

    const options1 = [
        {value: "optionA", label: "Option A"},
        {value: "optionB", label: "Option B"},
        {value: "optionC", label: "Option C"},
        {value: "optionD", label: "Option D"},
    ];

    const options2 = [
        {value: "first_option", label: "Option 1"},
        {value: "second_option", label: "Option 2"},
        {value: "third_option", label: "Option 3"},
        {value: "fourth_option", label: "Option 4"},
    ];

    const [vbs, setVbs] = React.useState([]);

    const options3 = [
        {value: "option1", label: "Option A1"},
        {value: "option2", label: "Option B1"},
        {value: "option3", label: "Option C1"},
        {value: "option4", label: "Option D1"},
    ];

    useEffect(() => {
        if (eoi) {
            // form.setValue("vbc_no", eoi?.vbc_no);
            // form.setValue("staff_in_charge", eoi?.staff_in_charge);
            // form.setValue("source", eoi?.source);
            console.log(form.getValues());
        }
        fetchVbs();

    }, [eoi]);

    function fetchVbs() {
        httpApi.get("/vacancy").then((response) => {
            setVbs([]);
            response.data.data.map((v) => {
                setVbs((vbs) => [...vbs, {value: v.job_title, label: v.job_title}]);
            })

        }, (error) => {
            console.log(error);
        })
    }


    return (
        <>
            <div className="pt-2">
                {/* Details */}
                <section className="flex flex-col w-full space-y-4 py-6">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="">
                            <div className="flex flex-col justify-between  space-y-6 ">
                                <FormField
                                    control={form.control}
                                    name="vbc_no"
                                    render={({field}) => (
                                        <FormItem className="max-w-48">
                                            {/*<FormLabel>*/}
                                            {/*    {eoi?.vbc_no}*/}
                                            {/*</FormLabel>*/}
                                            <FormControl>
                                                <MSelect disabled={!isEdit} {...field} variant="standard" label="VBC No." className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] rounded-none'}`}>
                                                    {vbs.map((option) => (
                                                        <Option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </MSelect>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="staff_in_charge"
                                    render={({field}) => (
                                        <FormItem className="max-w-48">
                                            {/*<FormLabel>*/}
                                            {/*    {eoi?.staff_in_charge}*/}
                                            {/*</FormLabel>*/}
                                            <FormControl>
                                                <MSelect disabled={!isEdit} {...field} variant="standard" label="Staff in charge" className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] rounded-none'}`}>
                                                    {options2.map((option) => (
                                                        <Option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </MSelect>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="source"
                                    render={({field}) => (
                                        <FormItem className="max-w-48">
                                            {/*<FormLabel>*/}
                                            {/*    {eoi?.source}*/}
                                            {/*</FormLabel>*/}
                                            <FormControl >
                                                        <MSelect disabled={!isEdit} {...field} variant="standard" label="Source" className={`${isEdit ? 'px-0' : 'px-2 !bg-white  !border-b-[1px] rounded-none'}`}>
                                                    {options3.map((option) => (
                                                        <Option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </MSelect>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            {isEdit && (
                            <div className="flex items-center justify-end">
                                <Button className="rounded-full bg-blue-400 text-white font-bold px-8">Save</Button>
                            </div>
                            )}
                        </form>
                    </Form>
                </section>
                { eoi.id && <HistoryLog modelName={"eoi"} modelID={eoi.id} /> }
            </div>
        </>
    );
}
