import React, { useEffect, useState } from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import PersonalTab from "./tabs/PersonalTab";
import DocumentsTab from "./tabs/DocumentsTab";
import DetailsTab from "./tabs/DetailsTab";
import ActionTab from "./tabs/ActionTab";
import NotesTab from "./tabs/NotesTab";
import { useParams } from "react-router-dom";
import { httpApi } from "../../api/http.api";
import { Pencil, X, Trash2 } from "lucide-react";


export default function EoiDetails() {
    const [activeTab, setActiveTab] = useState("personal");
    const { ID } = useParams();
    const [isNew, setIsNew] = useState(true);
    const [eoiDetails, setEoiDetails] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    function fetchEoiDetails() {
        try {
            httpApi.get(`/eoi/${ID}`).then(response => {
                setEoiDetails(response.data);
                setIsNew(false);
                setIsEdit(false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (ID) {
            fetchEoiDetails();
        } else {
            setEoiDetails({});
            setIsNew(true);
            setIsEdit(true);
        }
    }, [ID]);

    function deleteEoi() {
        httpApi.delete(`/eoi/${ID}`).then(() => {
            window.location.href = '/eoi';
        });
    }

    const tabs = [
        {
            label: "Personal",
            value: "personal",
            Component: PersonalTab
        },
        {
            label: "Details",
            value: "details",
            Component: DetailsTab
        },
        {
            label: "Action",
            value: "action",
            Component: ActionTab
        },
        {
            label: "Notes",
            value: "notes",
            Component: NotesTab
        },
        {
            label: "Document",
            value: "document",
            Component: DocumentsTab
        }
    ];

    return (
        <div className="flex flex-col max-md:ml-0 max-md:w-full ">
            <div className="flex relative flex-col grow px-5 pt-4 w-full bg-white rounded-lg border max-md:mt-10 max-md:max-w-full">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 w-[80%]"
                        indicatorProps={{
                            className: "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                        }}
                    >
                        {tabs.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => setActiveTab(value)}
                                className={activeTab === value ? "text-gray-900" : ""}
                            >
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody>
                        {tabs.map(({ value, Component }) => (
                            <TabPanel key={value} value={value}>
                                <Component eoi={eoiDetails} isEdit={isEdit} />
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>

                {!isNew && (
                    <div className="absolute right-4 top-6 cursor-pointer space-x-3">
                        <button onClick={() => setIsEdit(!isEdit)}>
                            {isEdit ?  <X   className="hover:text-red-400 text-gray-500 transition-all ease-in-out w-5 h-5"/> : <Pencil className="hover:text-gray-700 text-gray-500  transition-all ease-in-out w-5 h-5"/>}
                        </button>
                        <button onClick={deleteEoi} >
                            <Trash2 className="hover:text-gray-700 text-gray-500  transition-colors ease-in-out w-5 h-5"/>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
