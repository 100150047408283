import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from '../hooks/reduxHooks';
import {Link, useNavigate} from 'react-router-dom';
import {doLogin} from "../store/slices/authSlice";
import {notificationController} from "../controllers/notificationController";
import {useToast} from "@/components/ui/use-toast"
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "@/components/ui/card"
import {Label} from "@/components/ui/label"
import {Input} from "@/components/ui/input"
import {Checkbox} from "@/components/ui/checkbox"
import {Button} from "@/components/ui/button"

// interface LoginFormData {
//     email: string;
//     password: string;
// }

const Divider = ({className}) => (
    <div className={`shrink-0 w-px border border-solid ${className}`}/>
);

const Icon = ({src, alt, className}) => (
    <img loading="lazy" src={src} alt={alt} className={className}/>
);

export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {toast} = useToast();
    const token = useAppSelector((state) => state.auth.token);
    useEffect(() => {
        if (token) navigate("/", {replace: true})
    }, []);
    const [isLoading, setLoading] = useState(false);

    const [email, setEmail] = useState('zamil007@gmail.com')
    const [password, setPassword] = useState('password')

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const values = {email, password};
        dispatch(doLogin(values))
            .unwrap()
            .then((res) => navigate('/'))
            .catch((err) => {
                notificationController.error({message: err.message});
                setLoading(false);
            });

    };

    // setup the login api here
    // const login = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post(`${REACT_APP_API_URL}/auth/login`, {
    //             email: email,
    //             password: password
    //         })
    //         console.log(response.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return (
        // <div
        //     className="flex min-h-screen items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 dark:bg-gray-900">
        //     <div className="w-full max-w-md space-y-8">
        //         <div>
        //             <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50">
        //                 HomeCare Login
        //             </h2>
        //         </div>
        //         <form onSubmit={handleSubmit} className="mt-8 space-y-6" method="post">
        //             <input defaultValue="true" name="remember" type="hidden"/>
        //             <div className="-space-y-px rounded-md shadow-sm">
        //                 <div>
        //                     <label className="sr-only" htmlFor="email-address">
        //                         Email address
        //                     </label>
        //                     <input
        //                         autoComplete="email"
        //                         className="relative block w-full appearance-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        //                         id="email-address"
        //                         name="email"
        //                         value={email}
        //                         onChange={(e) => setEmail(e.target.value)}
        //                         placeholder="Email address"
        //                         required
        //                         type="email"
        //                     />
        //                 </div>
        //                 <div>
        //                     <label className="sr-only" htmlFor="password">
        //                         Password
        //                     </label>
        //                     <input
        //                         autoComplete="current-password"
        //                         className="relative block w-full appearance-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        //                         id="password"
        //                         name="password"
        //                         value={password}
        //                         onChange={(e) => setPassword(e.target.value)}
        //                         placeholder="Password"
        //                         required
        //                         type="password"
        //                     />
        //                 </div>
        //             </div>
        //             <div className="flex items-center justify-between">
        //                 <div className="flex items-center">
        //                     <input
        //                         className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        //                         id="remember-me"
        //                         name="remember-me"
        //                         type="checkbox"
        //                     />
        //                     <label className="ml-2 block text-sm text-gray-900 dark:text-gray-50" htmlFor="remember-me">
        //                         Remember me
        //                     </label>
        //                 </div>
        //                 <div className="text-sm">
        //                     <a
        //                         className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
        //                         href="#"
        //                     >
        //                         Forgot your password?
        //                     </a>
        //                 </div>
        //             </div>
        //             <div>
        //                 <button
        //                     className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        //                     type="submit"
        //                 >
        //       <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        //         <svg
        //             aria-hidden="true"
        //             className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
        //             fill="currentColor"
        //             viewBox="0 0 20 20"
        //             xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //               clipRule="evenodd"
        //               d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
        //               fillRule="evenodd"
        //           />
        //         </svg>
        //       </span>
        //                     Sign in
        //                 </button>
        //             </div>
        //         </form>
        //     </div>
        // </div>
        <div className="flex flex-col min-h-dvh bg-white">

            <header className="flex flex-col pt-3 w-full shadow-sm bg-neutral-100 max-md:max-w-full">
                <div
                    className="flex gap-5 justify-between items-center self-center px-5 w-full max-w-[1319px] max-md:flex-wrap max-md:max-w-full">
                    <div className="flex gap-5 self-stretch my-auto text-xs leading-5 text-slate-600">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c5a4aef61cf1b5ee5885e4c70a8baeea07f76475c566078cf5681ee3ffe1c85?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Company logo" className="shrink-0 aspect-[4] w-[73px]"/>
                        <Divider className="bg-slate-600 border-slate-600 h-[18px]"/>
                        <div className="flex-auto my-auto">Your HR Hub. Simplified!</div>
                    </div>
                    <form
                        className="flex gap-2.5 self-stretch px-3.5 py-3 text-sm font-medium leading-5 whitespace-nowrap bg-white border-0 border-solid border-slate-600 rounded-[34px] text-slate-600">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/01423d2ef7c685077346615b78c832ab6fa117eb9018ce1fce9d2b98f923da46?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Search icon" className="shrink-0 w-3.5 aspect-square fill-slate-600"/>
                        <label htmlFor="searchInput" className="sr-only">Search</label>
                        <input id="searchInput" type="text" placeholder="Search"
                               className="flex-auto my-auto bg-transparent border-none outline-none"/>
                    </form>
                    <nav className="flex gap-3 self-stretch my-auto">
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ceb5efce947d11ed31894b717e28b53cea48cb995073fae0bb38f0ecbe92311a?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Notification icon" className="shrink-0 aspect-square w-[18px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3cbdeb3d90ac01717e378a285cac0dc55f5654a68204382aed5cd916b007d1cf?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="Messages icon" className="shrink-0 aspect-[0.83] fill-slate-600 w-[15px]"/>
                        <Divider className="bg-gray-400 bg-opacity-50 border-gray-400 border-opacity-50 h-[18px]"/>
                        <Icon
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce0b0d783a7f5435d21cf8b00a9b3a59d7263e92117f876e6f29596ebf74214?apiKey=ae76636b06d44b88a66a58519c620a05&"
                            alt="User profile icon" className="shrink-0 self-start aspect-[1.06] w-[18px]"/>
                    </nav>
                </div>
                <div className="mt-2.5 w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full"/>
            </header>

            <main className="flex-1 flex items-center justify-center">
                <Card className="w-full max-w-md bg-gray-100">
                    <CardHeader className="space-y-1">
                        <CardTitle className="text-2xl text-center">Sign in to your account</CardTitle>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">Email</Label>
                            <Input id="email"
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                                   className={"bg-white"} type="email" placeholder="Email Address"/>
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="password">Password</Label>
                            <Input id="password"
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   className={"bg-white"} type="password"/>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <Checkbox id="remember"/>
                                <Label htmlFor="remember" className="text-sm font-medium">
                                    Remember me
                                </Label>
                            </div>
                            <Link href="#" className="text-sm font-medium hover:underline underline-offset-4"
                                  prefetch={false}>
                                Forgot password?
                            </Link>
                        </div>
                    </CardContent>
                    <CardFooter>
                        <Button onClick={(e) => handleSubmit(e)} className="w-full bg-gray-700">Sign in</Button>
                    </CardFooter>
                </Card>
            </main>

        </div>

    )
}