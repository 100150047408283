import MainLayout from "../components/MainLayout";
import { User, Bot,Loader, SendHorizontal } from "lucide-react";
import { httpApi } from "../api/http.api";
import {useEffect, useState} from "react";
import { useAutoAnimate } from '@formkit/auto-animate/react'

const AiMessage = ({ message }) => {
    return (
        <div className="flex items-start gap-4 mr-[10%]">
            <div className="flex-shrink-0 bg-gray-300 rounded-full p-2 flex items-center justify-center h-10 w-10">
                <Bot className="rounded-full w-5 h-5" />
            </div>
            <div className="bg-gradient-to-r from-gray-300 to-gray-400 p-3 rounded-lg  rounded-full rounded-tl-none">
                <p className="text-sm">{message}</p>
            </div>
        </div>
    );
};

const UserMessage = ({message}) => {
    return (
        <div className="flex items-end justify-end flex-col gap-4 ml-[10%]">
            <div className="flex items-end justify-end w-full gap-4">
                <div className="bg-gradient-to-r from-gray-400 to-gray-300 p-3 rounded-lg  rounded-full rounded-tr-none">
                    <p className="text-sm ">{message}</p>
                </div>
                <div className="flex-shrink-0 bg-gray-300 rounded-full p-2 flex items-center justify-center h-10 w-10">
                    <User className="rounded-full w-5 h-5" />
                </div>
            </div>
        </div>
    );
};

const LoadingIndicator = () => (
    <div className="flex justify-start items-center min-w-[200px]">
        <div className="relative flex space-x-2 items-center px-4">
            {[...Array(6)].map((_, index) => (
                <div
                    key={index}
                    className={`w-${6 - index} h-${6 - index} bg-gray-600 rounded-full animate-pulse `}
                    style={{ animationDelay: `${index * 0.2}s` }}
                ></div>
            ))}
        </div>
    </div>
);


const Content = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [parent, enableAnimations] = useAutoAnimate()

    const sendMsg = () => {
        setLoading(true);
        httpApi.post('/ai/generate', { content: message })
            .then(response => {
                const msg = response.data.message;
                setLoading(false);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'ai', content: msg }
                ]);
                setMessage('');
                console.log(messages);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };
    useEffect(() => {
        setMessages([{ type: 'ai', content: 'Hello, how can I help you today?' }]);
    }, []);

    const handleSentMsg = () => {
        if (message.trim() === '') {
            return;
        }
        setMessages(prevMessages => [
            ...prevMessages,
            { type: 'user', content: message },
        ]);
        setTimeout(() => {
            sendMsg();
        }, 300);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !loading) {
            e.preventDefault();
            handleSentMsg();
        }
    };

    return (
        <div className="container mx-auto px-6 py-6">
            <div className="flex justify-center">
                <div className="w-full lg:w-3/4">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center pb-3">
                            <h1 className="text-2xl font-bold">Chat</h1>
                            <div className="flex items-center">
                                <button className="bg-gray-200 text-gray-600 p-2 rounded-full hover:bg-gray-300">
                                    <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="chat-box overflow-y-auto px-4 h-[600px] space-y-4" ref={parent}>
                            {messages.map((msg, index) =>
                                msg.type === 'ai' ? (
                                    <AiMessage key={index} message={msg.content} />
                                ) : (
                                    <UserMessage key={index} message={msg.content} />
                                )
                            )}
                            {loading && <AiMessage message={<LoadingIndicator />} />}
                        </div>
                        <div className="flex items-center mt-3 gap-2">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                onKeyDown={handleKeyDown}
                                className="w-full px-4 py-3 rounded-lg bg-gray-200 outline-none"
                            />
                            <button
                                disabled={loading}
                                className={`text-white bg-gray-700 hover:bg-gray-600 px-6 py-3 rounded-lg ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={handleSentMsg}
                            >
                                {loading ? <Loader  className="animate-spin "/> :  <SendHorizontal />}

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function ChatSpacePage() {
    return (
        <MainLayout title={"Chat Space"} childTwo={<Content />} />
    );
}
