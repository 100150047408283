import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Select, Option, Typography } from '@material-tailwind/react';
import { useSettings } from "../../../context/SettingsContext";

export default function SettingsPreferencePage() {
    const panels = ['eoi', 'vacancy', 'applicant'];
    const [selectedPanel, setSelectedPanel] = useState(panels[0]);
    const { settings, setSettings } = useSettings();

    if (!settings) {
        return <div>Loading settings...</div>;
    }

    const handleFontSizeChange = (value) => {
        setSettings((prev) => ({ ...prev, fontSize: value }));
        // console.log(settings);
    };


    const handleSave = () => {
        // savePanelSettingsToAPI(selectedPanel, settings[selectedPanel] || {}).then(() => {
        //     console.log("Settings saved successfully");
        // });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = Number(value) || 0;

        setSettings((prev) => {
            const updatedSettings = {
                ...prev,
                [selectedPanel]: {
                    ...prev[selectedPanel],
                    [name]: numericValue,
                },
            };
                const section1Width = updatedSettings[selectedPanel].section1Width;
                // if (section1Width > 60) {
                //     updatedSettings[selectedPanel].section1Width = 60;
                //     updatedSettings[selectedPanel].section2Width = 100 - 60;
                // } else {
                    updatedSettings[selectedPanel].section2Width = 100 - section1Width;
                // }

            return updatedSettings;
        });
    };

    const currentPanelSettings = settings[selectedPanel] || { section1Width: 0, section2Width: 0 };

    return (
        <section className="flex flex-col p-4 space-y-6">
            <Card className="shadow-lg">
                <CardBody>
                    <Typography variant="h5" className="mb-4 font-semibold">
                        Preferences
                    </Typography>
                    <div className="flex gap-4 mb-4">
                        {panels.map((panel) => (
                            <Button
                                key={panel}
                                color={selectedPanel === panel ? 'blue' : 'gray'}
                                onClick={() => setSelectedPanel(panel)}
                            >
                                {panel.charAt(0).toUpperCase() + panel.slice(1)} Panel
                            </Button>
                        ))}
                    </div>
                    <Card className="shadow-none">
                        <CardBody>
                            <Typography variant="h6" className="mb-4">
                                {selectedPanel.charAt(0).toUpperCase() + selectedPanel.slice(1)} Panel Settings
                            </Typography>
                            <div className="flex flex-col gap-6">
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="flex flex-col">
                                        <Typography className="mb-2">Section 1 Width (%)</Typography>
                                        <input
                                            type="number"
                                            name="section1Width"
                                            value={currentPanelSettings.section1Width}
                                            onChange={handleInputChange}
                                            className="border rounded-lg p-2 text-gray-700"
                                            min="0"
                                            max="100"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <Typography className="mb-2">Section 2 Width (%)</Typography>
                                        <input
                                            type="number"
                                            name="section2Width"
                                            value={currentPanelSettings.section2Width}
                                            onChange={handleInputChange}
                                            className="border rounded-lg p-2 text-gray-700"
                                            min="0"
                                            max="100"
                                            disabled // This is optional, as section2Width is auto-calculated
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <Typography className="mb-2">Global Font Size:</Typography>
                                    <Select
                                        id="fontSize"
                                        name="fontSize"
                                        label="fontSize"
                                        value={settings.fontSize}
                                        onChange={(value) =>  handleFontSizeChange(value)}
                                    >
                                        <Option value="14px">Small</Option>
                                        <Option value="16px">Default</Option>
                                        <Option value="18px">Large</Option>
                                        <Option value="20px">Extra Large</Option>
                                    </Select>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter className="flex justify-end">
                            <Button color="blue" onClick={handleSave}>
                                Save Preferences
                            </Button>
                        </CardFooter>
                    </Card>
                </CardBody>
            </Card>
        </section>
    );
}
