import React from 'react';
import HistoryLog from "@/components/historyLog";

function RTWTab(props) {
    return (
        <div className='py-10'>
            <h1 className="text-2xl font-semibold text-gray-900 mx-auto flex items-center justify-center">RTW Tab</h1>
            <HistoryLog modelName={"eoi"} modelID={10}/>
        </div>
    );
}

export default RTWTab;