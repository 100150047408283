import {Input} from "@/components/ui/input";
import {useEffect, useState} from "react";
import {Heart, SendHorizontal} from "lucide-react";
import HistoryLog from "@/components/historyLog";
import dayjs from "dayjs";
import {httpApi} from "../../../api/http.api";

function NotesTab({eoi , isEdit}) {


    const [notes, setNotes] = useState([
        { id: 1, note: "This is a note lorepsm this is isiss twe faes fads adsf jdsafkl;lk;jdasf fdsadasfdsafkljlk  jlkjl;kadsf ojlk jkl;l dasfjkladsf" }
    ]);

    const [note, setNote] = useState("");
    const handleAddNote = () => {
        if (note.trim() === "") return;
        const newNote = {
            id: notes?.length + 1,
            note: note
        }
        // httpApi.post(`/eoi/${eoi.id}/notes`, {note: note}).then(response => {
            setNotes([...notes, newNote]);
        // })
        setNote("");
    }

    const formatDate = (date) => {
        const Thedate = dayjs(date);
        const day = Thedate.date();
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };
        const formattedDate = `${day}${daySuffix(day)} of ${Thedate.format('MMMM YYYY')} at ${Thedate.format('hh:mm A')}`;
        return formattedDate;
    };

    useEffect(() => {
        // console.log(eoi)
        // if (eoi) {
        //     setNotes(eoi?.notes);
        // }
    }, [eoi])

    return (
        <div className="flex flex-col gap-8 pt-2">
            <div >
                {notes && notes.map((note, index) => (
                    <div key={index + 1} className="flex items-center justify-between bg-gray-100 p-2 rounded-lg mt-2 border flex-col">
                        <div className="flex justify-start items-start w-full">
                            <span className="">Note {index + 1}</span>
                        </div>
                        <div className="flex items-start w-full">
                            <p className="ml-6 py-2 text-left">{note.note}</p>
                        </div>
                        <div className="flex justify-end items-end w-full">
                            <span>


                                By {note.author} on {formatDate(note.date)}
                            </span>
                        </div>
                        {/* <button onClick={() => setNotes(notes.filter((_, i) => i !== index))}>Delete</button> */}
                    </div>
                ))}
            </div>
            <div className="flex items-center w-[95%] mx-auto ">
                <div className="border flex justify-between w-full flex-1 ">
                    <Input placeholder="Type here ..." className="!border-none !shadow-none !outline-none !ring-0"
                           value={note} onChange={(e) => setNote(e.target.value)}/>
                    <div className="flex items-center justify-center px-2">
                        <Heart className="text-gray-600"/>
                    </div>
                </div>
                <div className="px-6 border h-full py-1.5" onClick={handleAddNote}>
                    <SendHorizontal className="text-gray-600"/>
                </div>
            </div>
            { eoi.id && <HistoryLog modelName={"notes"} modelID={eoi.id} /> }
        </div>
    )
}

export default NotesTab
