import React, {useState} from 'react'
import {Input} from '@/components/ui/input'
import {SendHorizontal} from 'lucide-react'
import MyDataTable from "@/components/tables/MyDataTable";
import {Checkbox} from "@material-tailwind/react";

export default function HistoryLog({modelName, modelID}) {
    const [filters, setFilters] = useState([])
    return (
        <>
            {modelName && modelID &&
                <div className='w-[100%] mx-auto flex flex-col justify-between h-full gap-2 py-4'>
                    <h1 className='w-fit text-gray-700 py-2'>History</h1>
                    <MyDataTable tableName={"history"} filters={filters}/>
                    <div className="flex items-center w-full mx-auto ">
                        <div className="border flex justify-between w-full flex-1 ">
                            <Input placeholder="Type here ..."
                                   className="!border-none !shadow-none !outline-none !ring-0"
                            />
                            <div className="flex items-center justify-center px-2">
                                <Checkbox defaultChecked ripple={false}
                                          className="h-5 w-5  border-gray-900/20 bg-gray-900/10 transition-all  hover:before:opacity-0"
                                />
                            </div>
                            <div className="px-6 border h-full py-2.5">
                                <SendHorizontal className="text-gray-600 cursor-pointer"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

