import React, {useState} from "react";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm} from "react-hook-form";
import {z} from "zod";
import {cn} from "@/lib/utils";
import {Button} from "@/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/ui/popover";
import {format} from "date-fns"
import {CalendarIcon, UserRound} from "lucide-react"
import {Input} from "@/components/ui/input";
import {useToast} from "@/components/ui/use-toast";
import {Calendar} from "@/components/ui/calendar"
import {Switch} from "@/components/ui/switch";
import {Dialog, DialogContent, DialogHeader, DialogTitle,} from "@/components/ui/dialog"
import {httpApi} from "../../../api/http.api";
import {useNavigate, useParams} from "react-router-dom";

const formSchema = z.object({
    name: z.string().nonempty("Name is required"),
    job_title: z.string().nonempty("Job title is required"),
    salary: z.string().nonempty("Current salary is required and must be positive"),
    department: z.string().nonempty("Department is required"),
    region: z.string().nonempty("Region is required"),
    last_day: z.date("Last working day is required"),
    reason: z.string().nonempty("Reason is required"),
    other_reason: z.string().optional(),
    start_date: z.date("Proposed start date is required"),
    hours_per_week: z.string().nonempty("Hours per week is required"),
    job_desc_change: z.boolean(),
    job_desc_changes: z.string().optional(),
    hours_change: z.boolean(),
    proposed_hours: z.string().optional(),
    salary_change: z.boolean(),
    proposed_salary: z.string().optional(),
    justification: z.string().nonempty("Justification is required"),
    consequences: z.string().nonempty("Consequences are required"),
    additional_notes: z.string().optional(),
    save_template: z.boolean().optional(),
    template_name: z.string().optional()
});


export default function ReplacementVacancyForm() {
    const {toast} = useToast()
    const [dialogOpen, setDialogOpen] = useState(false)
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const {ID} = useParams();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
                 name: "",
                job_title: "",
                salary: "",
                department: "",
                region: "",
                last_day: "",
                reason: "",
                other_reason: "",
                hours_per_week: "",
                start_date: new Date(),
                job_desc_change: false,
                job_desc_changes: "",
                hours_change: false,
                proposed_hours: "",
                salary_change: false,
                proposed_salary: "",
                justification: "",
                consequences: "",
                additional_notes: "",
                save_template: false,
                template_name: ""
        },
    });

    async function onSubmit(values) {
        try {
            console.log(values);
            const data = {
                ...values,
                is_replacement: true,
                is_template: values.save_template,
                template_name: values.save_template ? values.job_title : ""
            };

            let response;
            if (isEdit) {
                response = await httpApi.patch(`/vacancy/${ID}`, data);
            } else {
                response = await httpApi.post("/vacancy/create/replacement", data);
            }

            if (response && response.data) {
                console.log(response.data); // Log the entire response data to inspect its structure
                const id = response.data.id; // Ensure this matches the actual structure of your response
                setDialogOpen(true);
                if (id) {
                    setTimeout(() => {
                        setDialogOpen(false);
                        navigate(`/vacancy/${id}`);
                    }, 3000);
                    toast(isEdit ? "Vacancy Updated Successfully" : "Vacancy Created Successfully");
                } else {
                    console.error("ID not found in response data");
                }
            } else {
                console.error("No response data");
            }
        } catch (error) {
            console.error(error);
        }
    }


    const options1 = [
        {value: "optionA", label: "Option A"},
        {value: "optionB", label: "Option B"},
        {value: "optionC", label: "Option C"},
        {value: "optionD", label: "Option D"},
    ];
    const onerror = (errors) => {
        console.log(errors);
    }

    return (
        <>
            <div className="">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit,onerror)} className="space-y-8">
                        <div className="border rounded-xl bg-white dark:bg-slate-800 p-6">

                            {/* position detials */}
                            <section className="space-y-6">
                                <div className="flex items-center justify-start gap-3 font-semibold">
                                    <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                    Position Details
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input placeholder="Name of person to be replaced"
                                                           custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="job_title"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input placeholder="Job Title of person to be relaced"
                                                           custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="department"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Controller
                                                        name="department"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...field}
                                                                onValueChange={(value) => field.onChange(value)}
                                                            >
                                                                <SelectTrigger className=" text-gray-500" custom={true}>
                                                                    <SelectValue placeholder="Department/Team"/>
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectGroup>
                                                                        {options1.map((option) => (
                                                                            <SelectItem key={option.value}
                                                                                        value={option.value}>
                                                                                {option.label}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectGroup>
                                                                </SelectContent>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="salary"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input placeholder="Current Salary/Rate of Pay" custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="hours_per_week"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input placeholder="Hours / Week" custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="region"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Controller
                                                        name="region"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...field}
                                                                onValueChange={(value) => field.onChange(value)}
                                                            >
                                                                <SelectTrigger className=" text-gray-500" custom={true}>
                                                                    <SelectValue placeholder="Place/Region"/>
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectGroup>
                                                                        {options1.map((option) => (
                                                                            <SelectItem key={option.value}
                                                                                        value={option.value}>
                                                                                {option.label}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectGroup>
                                                                </SelectContent>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />


                                </div>
                            </section>

                            {/* Replacement Case */}
                            <section className="space-y-6 mt-8">
                                <div className="flex items-center justify-start gap-3 font-semibold">
                                    <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                    Replacement option
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <FormField
                                        control={form.control}
                                        name="reason"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Controller
                                                        name="reason"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...field}
                                                                onValueChange={(value) => field.onChange(value)}
                                                            >
                                                                <SelectTrigger className=" text-gray-500" custom={true}>
                                                                    <SelectValue placeholder="Reason for replacement"/>
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectGroup>
                                                                        {options1.map((option) => (
                                                                            <SelectItem key={option.value}
                                                                                        value={option.value}>
                                                                                {option.label}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectGroup>
                                                                </SelectContent>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="other_reason"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input placeholder="if other,please specify"
                                                           custom={true}  {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="last_day"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <FormControl>
                                                                <Button
                                                                    variant={"outline"}
                                                                    className={cn(
                                                                        "w-full pl-3 text-left font-normal border-0 shadow-none border-b-2 border-b-gray-300 rounded-none",
                                                                        !field.value && "text-muted-foreground"
                                                                    )}
                                                                >
                                                                    {field.value ? (
                                                                        format(field.value, "PPP")
                                                                    ) : (
                                                                        <span className="truncate">Last working day of current employee</span>
                                                                    )}
                                                                    <CalendarIcon
                                                                        className="ml-auto h-4 w-4 opacity-50"/>
                                                                </Button>
                                                            </FormControl>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                mode="single"
                                                                selected={field.value}
                                                                onSelect={field.onChange}
                                                                disabled={(date) =>
                                                                    date > new Date() || date < new Date("1900-01-01")
                                                                }
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </section>

                            {/* New Role Details */}
                            <section className="space-y-6 mt-8">
                                <div className="flex items-center justify-start gap-3 font-semibold">
                                    <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                    New Role Details
                                </div>
                                <div className="grid grid-cols-1 gap-6 w-full">
                                    <FormField
                                        control={form.control}
                                        name="proposed_start_date"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <FormControl>
                                                                <Button
                                                                    variant={"outline"}
                                                                    className={cn(
                                                                        "w-fit min-w-[240px] pl-3 text-left font-normal border-0 shadow-none border-b-2 border-b-gray-300 rounded-none",
                                                                        !field.value && "text-muted-foreground"
                                                                    )}
                                                                >
                                                                    {field.value ? (
                                                                        format(field.value, "PPP")
                                                                    ) : (
                                                                        <span
                                                                            className="truncate">Proposed Start Date</span>
                                                                    )}
                                                                    <CalendarIcon
                                                                        className="ml-auto h-4 w-4 opacity-50"/>
                                                                </Button>
                                                            </FormControl>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                mode="single"
                                                                selected={field.value}
                                                                onSelect={field.onChange}
                                                                disabled={(date) =>
                                                                    date > new Date() || date < new Date("1900-01-01")
                                                                }
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <div className="flex items-center justify-start gap-6">

                                        <FormField
                                            control={form.control}
                                            name="job_desc_change"
                                            render={({field}) => (
                                                <FormItem>
                                                    <div className="flex justify-center gap-4">
                                                        <div className="text-gray-600 flex items-center">
                                                            Any Changes to job description ?
                                                        </div>
                                                        <div className="flex flex-col  justify-center gap-1">
                                                            <span className="text-sm text-gray-500">
                                                                Yes/No
                                                            </span>
                                                            <FormControl>
                                                                <Switch checked={field.value}
                                                                        onCheckedChange={field.onChange} {...field} />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        {form.getValues("job_desc_change") && (
                                            <FormField
                                                control={form.control}
                                                name="job_desc_changes"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input placeholder="if yes,describe changes" custom={true} {...field} />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-center justify-start gap-6">

                                        <FormField
                                            control={form.control}
                                            name="hours_change"
                                            render={({field}) => (
                                                <FormItem>
                                                    <div className="flex justify-center gap-4">
                                                        <div className="text-gray-600 flex items-center">
                                                            Any Changes to working hours ?
                                                        </div>
                                                        <div className="flex flex-col  justify-center gap-1">
                                                            <span className="text-sm text-gray-500">
                                                                Yes/No
                                                            </span>
                                                            <FormControl>
                                                                <Switch checked={field.value}
                                                                        onCheckedChange={field.onChange} {...field} />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        {form.getValues("hours_change") && (
                                            <FormField
                                                control={form.control}
                                                name="proposed_hours"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input placeholder="if yes, new proposed hours" custom={true} {...field} />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-center justify-start gap-6">

                                        <FormField
                                            control={form.control}
                                            name="salary_change"
                                            render={({field}) => (
                                                <FormItem>
                                                    <div className="flex justify-center gap-4">
                                                        <div className="text-gray-600 flex items-center">
                                                            Any Changes to salary ?
                                                        </div>
                                                        <div className="flex flex-col  justify-center gap-1">
                                                            <span className="text-sm text-gray-500">
                                                                Yes/No
                                                            </span>
                                                            <FormControl>
                                                                <Switch checked={field.value}
                                                                        onCheckedChange={field.onChange} {...field} />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        {form.getValues("salary_change") && (
                                            <FormField
                                                control={form.control}
                                                name="proposed_salary"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Input placeholder="if yes, new proposed salary" custom={true} {...field} />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>

                            </section>

                            {/* Business Impact */}
                            <section className="space-y-6 mt-8">
                                <div className="flex items-center justify-start gap-3 font-semibold">
                                    <UserRound className="text-[10px] w-4 h-4 text-gray-500"/>
                                    Business impact
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <FormField
                                        control={form.control}
                                        name="justification"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Justification for replacement
                                                </FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter Justification" type="textarea" custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="consequences"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Consequences of not replacing
                                                </FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter Text" custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="additional_notes"
                                        render={({field}) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Additional Notes
                                                </FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Enter Text" custom={true} {...field} />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="border rounded-xl bg-white p-6 dark:bg-slate-800">
                            <section className="justify-between space-y-6 mt-6">
                                <div className="flex items-center justify-start gap-4">
                                    <div className="text-sm text-gray-500">
                                        Do you want to save as Template
                                        if Yes,promt for Template Name
                                    </div>
                                    <FormField
                                        control={form.control}
                                        name="save_template"
                                        render={({field}) => (
                                            <FormItem>
                                                <div className="flex items-center justify-center gap-1 flex-col">
                                                    <FormLabel className="text-gray-600">
                                                        Yes/No
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Switch checked={field.value}
                                                                onCheckedChange={field.onChange} {...field} />
                                                    </FormControl>
                                                </div>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="flex gap-4 justify-end ">
                                    <div className="flex flex-col items-center justify-center">
                                        <Button type="submit" className="">
                                            Save Progress
                                        </Button>
                                        {/*<span className="text-gray-500 text-sm">Last Saved at 2:55pm</span>*/}
                                    </div>
                                    <div className="flex flex-col items-center justify-center">
                                        <Button type="submit" className="" >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </section>
                        </div>


                    </form>
                </Form>
                <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                    <DialogContent
                        className="sm:max-w-[625px] py-24 px-16 border flex items-center flex-col justify-center">
                        <DialogHeader className="pb-6">
                            <DialogTitle className="text-3xl">
                                VBC Created Successfully
                            </DialogTitle>

                        </DialogHeader>
                        <div className="flex flex-col gap-6 p-4 px-8 text-gray-500">
                            <p>
                                VB Case ID 40222
                            </p>
                            <p>
                                Date Create 25th May 2024
                            </p>
                            <p>
                                Created By John Doe
                            </p>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
}
