import React, {useState , useEffect} from 'react';
import {Button, Card, CardBody, CardFooter, Checkbox, Typography} from '@material-tailwind/react';
import {httpApi} from "../../../api/http.api";

const rolesData = [
    {
        id: 1, name: 'Admin', permissions:
            {
                vacancy: {Create: true, Read: true, Update: true, Delete: true},
                eoi: {Create: true, Read: true, Update: true, Delete: true},
                user: {Create: true, Read: true, Update: true, Delete: true},
                applicant: {Create: true, Read: true, Update: true, Delete: true}
            }
    },
    {
        id: 2, name: 'Editor', permissions:
            {
                vacancy: {Create: false, Read: true, Update: true, Delete: false},
                eoi: {Create: false, Read: true, Update: true, Delete: false},
                user: {Create: false, Read: true, Update: true, Delete: false},
                applicant: {Create: false, Read: true, Update: true, Delete: false}
            }
    },
    {
        id: 3, name: 'Viewer', permissions:
            {
                vacancy: {Create: false, Read: true, Update: false, Delete: false},
                eoi: {Create: false, Read: true, Update: false, Delete: false},
                user: {Create: false, Read: true, Update: false, Delete: false},
                applicant: {Create: false, Read: true, Update: false, Delete: false}
            }
    },
];

export default function SettingsRolePage() {
    const [roles, setRoles] = useState(rolesData);
    const [selectedRole, setSelectedRole] = useState(roles[0]);

    useEffect(() => {
        // fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await httpApi.get('/roles');
            setRoles(response.data);
            if (response.data.length > 0) {
                setSelectedRole(response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handlePermissionChange = (category, permission) => {
        const updatedRole = { ...selectedRole };
        updatedRole.permissions[category][permission] = !updatedRole.permissions[category][permission];
        setSelectedRole(updatedRole);
    };

    const handleRoleChange = (role) => {
        setSelectedRole(role);
    };

    const handleSave = async () => {
        try {
            const updatedRoles = roles.map((role) =>
                role.id === selectedRole.id ? selectedRole : role
            );
            setRoles(updatedRoles);
            await httpApi.post('/roles', updatedRoles);
            console.log('Roles updated successfully:', updatedRoles);
        } catch (error) {
            console.error('Error saving roles:', error);
        }
    };

    return (
        <section className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full min-w-full">
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Card>
                        <CardBody>
                            <Typography variant="h5" className="mb-4">
                                Roles
                            </Typography>
                            <ul className="mb-4 ">
                                {roles.map((role) => (
                                    <li key={role.id} className="m-4">
                                        <Button
                                            className="w-[50%]"
                                            color={role.id === selectedRole.id ? 'blue' : 'gray'}
                                            onClick={() => handleRoleChange(role)}
                                        >
                                            {role.name}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </CardBody>
                    </Card>
                    <Card className="col-span-2 px-4">
                        <CardBody>
                            <Typography variant="h5" className="mb-2">
                                Permissions for {selectedRole.name}
                            </Typography>
                            <div className="grid grid-cols-1 gap-4">
                                {Object.entries(selectedRole.permissions).map(([category, permissions]) => (
                                    <div key={category}>
                                        <Typography variant="h6">{category}</Typography>
                                        <div className="flex w-max gap-4">
                                            {Object.entries(permissions).map(([permission, value]) => (
                                                <div key={`${category}-${permission}`} className="flex items-center justify-between">
                                                    <Typography>{permission}</Typography>
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={() => handlePermissionChange(category, permission)}
                                                        ripple={true}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                        <CardFooter>
                            <Button color="blue" onClick={handleSave}>
                                Save Changes
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </section>
    );
}
