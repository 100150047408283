import { useState } from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import { ChartConfig, ChartContainer } from "@/components/ui/chart";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ChevronDown ,Check, X ,CalendarDays,Loader} from "lucide-react";
import ProgressBar from "@/components/progressBar";
import CountCard from "../../components/CountCard";

const monthlyData = [
  { month: "January", desktop: 186, mobile: 80 },
  { month: "February", desktop: 305, mobile: 200 },
  { month: "March", desktop: 237, mobile: 120 },
  { month: "April", desktop: 73, mobile: 190 },
  { month: "May", desktop: 209, mobile: 130 },
  { month: "June", desktop: 214, mobile: 140 },
];

const yearlyData = [
  { year: 2019, desktop: 1200, mobile: 600 },
  { year: 2020, desktop: 1500, mobile: 800 },
  { year: 2021, desktop: 1800, mobile: 900 },
  { year: 2022, desktop: 1700, mobile: 1000 },
  { year: 2023, desktop: 2000, mobile: 1100 },
];

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#2563eb",
  },
  mobile: {
    label: "Mobile",
    color: "#60a5fa",
  },
};

export default function EoiChart() {
  const [filter, setFilter] = useState("Monthly");
  const [chartData, setChartData] = useState(monthlyData);

  const items = [
    {id: 0, name: "Active Expression" , icon : <Loader /> , count: "12"},
    {id: 1, name: " Success Applications" , icon : <Check /> , count: "06"},
    {id: 2, name: "Failed Expression" , icon : <X /> , count: "04"},
    {id: 3, name: "Active Months" , icon : <CalendarDays /> , count: "08"},
  ]

  const progressBarData = [
    { name: 'Cristin', progress: 60 },
    { name: 'Irene', progress: 80 },
    { name: 'Andria', progress: 45 },
    { name: 'Mike', progress: 75 },
  ];


  const handleFilterChange = (value) => {
    setFilter(value);
    if (value === "monthly") {
      setChartData(monthlyData);
    } else if (value === "yearly") {
      setChartData(yearlyData);
    }
  };

  return (
      <div className="bg-white border rounded-xl p-4 dark:bg-slate-800 min-w-[500px]">
        <div className="flex justify-between pb-2">
          {/*<h1 className="text-xl">Eoi Chart</h1>*/}
          <div />
          <Select onValueChange={handleFilterChange}>
            <SelectTrigger
                className="w-[100px] font-bold rounded-full !h-8 bg-gray-50 text-sm border border-gray-400 text-gray-500 !outline-none !ring-0"
            >
              <SelectValue placeholder={filter}/>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="monthly">Monthly</SelectItem>
                <SelectItem value="yearly">Yearly</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid vertical={false}/>
            <XAxis dataKey={filter === "monthly" ? "month" : "year"}/>
            <YAxis/>
            <Tooltip/>
            <Bar dataKey="desktop" fill={chartConfig.desktop.color} radius={4}/>
            <Bar dataKey="mobile" fill={chartConfig.mobile.color} radius={4}/>
          </BarChart>
        </ChartContainer>
        <div className="border-t  ">
          <div className="grid gap-y-4 gap-x-2 mt-6"
               style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}>
            {items.map((item, index) => (
                <CountCard count={item.count} icon={item.icon} index={index} key={index} name={item.name}/>
            ))}
          </div>
        </div>

        <div className="flex gap-4 py-4 flex-wrap border-t mt-6">
          {progressBarData.map((item) => (
              <ProgressBar key={item.name} name={item.name} progress={item.progress}/>
          ))}
        </div>
      </div>
  );
}
